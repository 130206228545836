import {
  Box,
  Heading,
  Link,
  Text,
  Container,
  Stack,
  Flex,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getEndpoint } from "../api";
import { Link as ReactRouterLink } from "react-router-dom";
import RankList from "../components/RankList";
import Countdown from "../components/Countdown";
import Testimony from "../components/Testimony";

export default function FiftyDays() {
  const [soulsWon, setSoulsWon] = useState(0);
  const [rorDist, setRorDist] = useState(0);
  const [reached, setReached] = useState(0);
  const [data, setData] = useState([]);

  const getSouls = async () => {
    const endpoint = getEndpoint("50days/souls");

    try {
      const res = await fetch(endpoint);
      const data = await res.json();

      setData(data);
    } catch (error) {
      console.log(error);
      console.log("fetch failed, " + endpoint);
    }
  };

  const updateCounter = () => {
    setSoulsWon(0);
    setRorDist(0);
    setReached(0);
    let x = 0;
    let y = 0;

    data.map((item) => {
      if (item.gaveROR) {
        x++;
      }
      if (item.salvation) {
        y++;
      }

      return <li>Test</li>;
    });

    setSoulsWon(y);
    setRorDist(x);

    setReached(data.length);
  };

  useEffect(() => {
    getSouls();
    updateCounter();
  }, []);

  useEffect(() => {
    updateCounter();
  });

  return (
    <Container maxW={"5xl"}>
      <Stack
        textAlign={"center"}
        align={"center"}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 5, md: 8 }}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: "3xl", sm: "3xl", md: "3xl" }}
          lineHeight={"110%"}
        >
          100 Days of{" "}
          <Text as={"span"} color={"project.theme"}>
            Gathering the Harvest
          </Text>
        </Heading>

        <Countdown
          timeTillDate="04 20 2022, 6:00 pm"
          timeFormat="MM DD YYYY, h:mm a"
        />
        <Box w="100%">
          <Box
            border={"2px #d9926e outset"}
            borderRadius={"md"}
            padding={"2%"}
            rounded={"xl"}
            boxShadow="lg"
          >
            <Box
              textAlign={"center"}
              w={{ base: "md", md: "33%" }}
              display={{ base: "initial", md: "inline-block" }}
            >
              <Text color={"project.theme"} fontWeight={"bold"}>
                Witnessed To
              </Text>
              <Box borderRadius={"sm"}>{reached}</Box>
            </Box>
            <Box
              textAlign={"center"}
              w={{ base: "md", md: "33%" }}
              display={{ base: "initial", md: "inline-block" }}
            >
              <Text color={"project.theme"} fontWeight={"bold"}>
                ROR Distributed
              </Text>
              <Box borderRadius={"sm"}>{rorDist}</Box>
            </Box>
          </Box>
          <Link
            as={ReactRouterLink}
            to="/100-days-register"
            display={"block"}
            color={"blue"}
          >
            Upload Outreach Info
          </Link>
        </Box>
        <RankList />
        <Testimony />
      </Stack>
      {/* <VideoGrid /> */}
      {/* <ImageGrid /> */}
    </Container>
  );
}
