import { Box, Center, Heading, SimpleGrid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import FellowshipCard from "./Fellowship";
import { useParams } from "react-router";
import { Flex } from "@chakra-ui/react";
import { images } from "../assets/fellowships";

export default function Location({ code }) {
  const [data, setData] = useState({});
  // let { code } = useParams();

  useEffect(() => {
    setData(LOCATIONS.find((x) => x.code === code));
  }, []);

  if (!data) {
    return (
      <Center>
        <Heading color="project.theme">Loading</Heading>
      </Center>
    );
  }

  return (
    <Box>
      <FellowshipGrid children={data.children} />
    </Box>
  );
}

export const FellowshipGrid = ({ children }) => {
  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      wrap="wrap"
      justify="center"
      align="center"
    >
      {children &&
        children.map((child) => (
          <FellowshipCard
            leader={child.leader}
            name={child.name}
            meetings={child.meetings}
            picture={images[child.picture]}
            socials={child.socials}
          />
        ))}
    </Flex>
  );
};

const LOCATIONS: Array<NavItem> = [
  {
    location: "Ontario",
    code: "ON",
    children: [
      {
        leader: "Pastor Nigel Dara",
        name: "York University",
        meetings: [
          {
            dayTime: "Contact Us",
            location: "online",
          },
        ],
        picture: "york",
        contact: "Contact Us",
        socials: [
          {
            name: "Instagram",
            link: undefined,
          },
        ],
      },
      {
        leader: "Brother Joshua Kehinde",
        name: "Toronto Metropolitan University ",
        meetings: [
          {
            dayTime: "Contact Us",
            location: "online",
          },
        ],
        picture: "tmu",
        contact: "Contact Us",
        socials: [
          {
            name: "Instagram",
            link: undefined,
          },
        ],
      },
      {
        leader: "Sister Natasha Dara",
        name: "University of Toronto, Mississauga",
        meetings: [
          {
            dayTime: "Contact Us",
            location: "online",
          },
        ],
        picture: "utm",
        contact: "Contact Us",
        socials: [
          {
            name: "Instagram",
            link: undefined,
          },
        ],
      },
      {
        leader: "",
        name: "University of Toronto, Scarborough",
        meetings: [
          {
            dayTime: "Contact Us",
            location: "online",
          },
        ],
        picture: "sample",
        contact: "Contact Us",
        socials: [
          {
            name: "Instagram",
            link: undefined,
          },
        ],
      },
      {
        leader: "Sister Kay Mushikiwase",
        name: "University of Ottawa",
        meetings: [
          {
            dayTime: "Contact Us",
            location: "online",
          },
        ],
        picture: "ottawa",
        contact: "Contact Us",
        socials: [
          {
            name: "Instagram",
            link: undefined,
          },
        ],
      },
      {
        leader: "Sister Alexandra Tshileo",
        name: "Carleton University",
        meetings: [
          {
            dayTime: "Contact Us",
            location: "online",
          },
        ],
        picture: "carleton",
        contact: "Contact Us",
        socials: [
          {
            name: "Instagram",
            link: undefined,
          },
        ],
      },
      {
        leader: "Sis Jasmine Obazogbon",
        name: "University of Toronto - St.George",
        meetings: [
          {
            dayTime: "Contact Us",
            location: "online",
          },
        ],
        picture: "sample",
        contact: "Contact Us",
        socials: [
          {
            name: "Instagram",
            link: undefined,
          },
        ],
      },
      {
        leader: "Sister Chloe Isesele",
        name: "University of Waterloo",
        meetings: [
          {
            dayTime: "Contact Us",
            location: "online",
          },
        ],
        picture: "sample",
        contact: "Contact Us",
        socials: [
          {
            name: "Instagram",
            link: undefined,
          },
        ],
      },
      {
        leader: "Sis Anita Ibhakhomu",
        name: "Brock University",
        meetings: [
          {
            dayTime: "Contact Us",
            location: "online",
          },
        ],
        picture: "sample",
        contact: "Contact Us",
        socials: [
          {
            name: "Instagram",
            link: undefined,
          },
        ],
      },
      {
        leader: "Bro Nathan Tshileo",
        name: "Algonquin College",
        meetings: [
          {
            dayTime: "Contact Us",
            location: "online",
          },
        ],
        picture: "sample",
        contact: "Contact Us",
        socials: [
          {
            name: "Instagram",
            link: undefined,
          },
        ],
      },
    ],
  },
  {
    location: "Alberta",
    code: "AB",
    children: [
      {
        leader: "Brother Anietie Akpanusoh",
        name: "University of Alberta",
        meetings: [
          {
            dayTime: "Contact Us",
            location: "online",
          },
        ],
        picture: "sample",
        contact: "Contact Us",
        socials: [
          {
            name: "Instagram",
            link: undefined,
          },
        ],
      },
      {
        leader: "Brother Enoch Caron",
        name: "University of Calgary",
        meetings: [
          {
            dayTime: "Contact Us",
            location: "online",
          },
        ],
        picture: "calgary",
        contact: "Contact Us",
        socials: [
          {
            name: "Instagram",
            link: undefined,
          },
        ],
      },
      {
        leader: "Pastor Tosin Ajibulu",
        name: "University of Lethbridge",
        meetings: [
          {
            dayTime: "Contact Us",
            location: "online",
          },
        ],
        picture: "lethbridge",
        contact: "Contact Us",
        socials: [
          {
            name: "Instagram",
            link: undefined,
          },
        ],
      },
    ],
  },

  {
    location: "Vancouver",
    code: "BC",
    children: [],
  },
  {
    location: "Newfoundland & Labrador",
    code: "NL",
    children: [
      {
        leader: "Sis Naomi Esosa Ighodaro",
        name: "Memorial University of Newfoundland",
        meetings: [
          {
            dayTime: "Contact Us",
            location: "online",
          },
        ],
        picture: "memorial",
        contact: "Contact Us",
        socials: [
          {
            name: "Instagram",
            link: undefined,
          },
        ],
      },
    ],
  },
  {
    location: "Manitoba",
    code: "MB",
    children: [
      {
        leader: "Sister Juanita Jerry-Egemba",
        name: "University of Manitoba",
        meetings: [
          {
            dayTime: "Contact Us",
            location: "online",
          },
        ],
        picture: "winnipeg",
        contact: "Contact Us",
        socials: [
          {
            name: "Instagram",
            link: undefined,
          },
        ],
      },
      {
        leader: "Bro Jesse Ajai",
        name: "Canadian Mennonite University",
        meetings: [
          {
            dayTime: "Contact Us",
            location: "online",
          },
        ],
        picture: "sample",
        contact: "Contact Us",
        socials: [
          {
            name: "Instagram",
            link: undefined,
          },
        ],
      },
      {
        leader: "Sis Zenita Olayemi",
        name: "University College of the North - The Pas",
        meetings: [
          {
            dayTime: "Contact Us",
            location: "online",
          },
        ],
        picture: "sample",
        contact: "Contact Us",
        socials: [
          {
            name: "Instagram",
            link: undefined,
          },
        ],
      },
    ],
  },
  {
    location: "New Brunswick",
    code: "NB",
    children: [],
  },
  {
    location: "Prince Edward Island",
    code: "PE",
    children: [],
  },
  {
    location: "Nova Scotia",
    code: "NS",
    children: [],
  },
  {
    location: "Saskatchewan",
    code: "SK",
    children: [],
  },
  // No data provided for these territories
  {
    location: "Northwest Territories",
    code: "NT",
    children: [],
  },
  {
    location: "Yukon",
    code: "YT",
    children: [],
  },
  {
    location: "Nunavut",
    code: "NU",
    children: [],
  },
];
