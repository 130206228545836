import Form from "./Form";
import { useState } from "react";
import InputFormat from "./InputFormat";
import { getEndpoint, getOptions } from "../api";
import InputTextArea from "./InputTextArea";
import {
  Box,
  Center,
  Flex,
  Heading,
  Icon,
  Image,
  SimpleGrid,
  Stack,
  Text,
  Link,
} from "@chakra-ui/react";
import cnc from "../assets/cnc.jpg";
import { AiFillCalendar, AiFillHome, AiFillProfile } from "react-icons/ai";
import { FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";

const Feature = ({ title, text, icon }: FeatureProps) => {
  return (
    <Stack textAlign="center" bg={"gray.50"} padding={"2"} rounded={"lg"}>
      <Flex
        w={16}
        h={16}
        align={"center"}
        justify={"center"}
        color={"project.theme"}
        rounded={"full"}
        mb={1}
        margin="auto"
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text color={"gray.600"}>{text}</Text>
    </Stack>
  );
};

export default function ProgramRegistration() {
  const [email, setEmail] = useState("");
  const [personName, setPersonName] = useState("");
  const [comment, setComment] = useState("");
  const [force, setForce] = useState(false);
  const [name, setName] = useState(`Register for more information`);
  const [text, setText] = useState("");
  const [comments, setComments] = useState("");
  const [questions, setQuestions] = useState("");
  const [number, setNumber] = useState("");

  const registerExhibition = async (e) => {
    e.preventDefault();
    if (email && personName && comment) {
      let packet = {
        name: personName,
        email: email,
        comment: comment,
        comments: comments,
        questions: questions,
        number: number,
      };

      const options = getOptions(packet);
      const endpoint = getEndpoint("program_register");

      try {
        const res = await fetch(endpoint, options);
        const data = await res.json();

        if (data === true) {
          console.log("here");
          setName("THANK YOU FOR REGISTERING!");
          setText(
            "Please look out for all communication regarding the program"
          );
          setForce(!force);
        } else if (data === "Person already registered") {
          setName("Already Registered");
          setText(
            "This email has already been used to register. Please make sure you're not duplicating"
          );
          setForce(!force);
        }
      } catch (error) {
        console.log(error);
        alert("Registration failed, please try again");
      }
    } else {
      if (!personName) {
        alert("Put in your name first");
      } else if (!email) {
        alert("Add your email first");
      } else if (!comment) {
        alert("Add your university first");
      } else if (!number) {
        alert("Add your phone number first");
      }
    }
  };

  let link = {
    link: "https://kingschat.online/user/blwcan",
    text: "Follow us on KingsChat to stay up to date",
  };

  return (
    <Center>
      <Stack
        bg={"gray.100"}
        rounded={"xl"}
        p={{ base: 2 }}
        spacing={{ base: 2 }}
      >
        <Image src={cnc} margin={"auto"} maxW="50%" p="2%" w="500px" />

        <Form
          name={name}
          optionalEntries={false}
          submitFunction={registerExhibition}
          submitText="Register"
          text={text}
          force={force}
          link={link}
          info={true}
        >
          <InputFormat
            inputName="Full Name"
            ifApplicable={false}
            inputType="text"
            inputPlaceholder="John"
            inputValue={personName}
            returnFunction={setPersonName}
          />
          <InputFormat
            inputName="Email"
            ifApplicable={false}
            inputType="email"
            inputPlaceholder="sample@email.com"
            inputValue={email}
            returnFunction={setEmail}
          />
          <InputFormat
            inputName="Phone Number"
            ifApplicable={false}
            inputType="number"
            inputPlaceholder="4161234567"
            inputValue={number}
            returnFunction={setNumber}
          />
          <InputFormat
            inputName="University"
            ifApplicable={false}
            inputType="text"
            inputPlaceholder="York University"
            inputValue={comment}
            returnFunction={setComment}
          />
          <InputTextArea
            inputName="Comments"
            ifApplicable={false}
            inputPlaceholder="Other relevant information, city, day of arrival, program of interest, etc."
            inputValue={comments}
            returnFunction={setComments}
          />
          <InputTextArea
            inputName="Questions you have for us"
            ifApplicable={false}
            inputPlaceholder="'How do I get addmission into my program of interest?' etc."
            inputValue={questions}
            returnFunction={setQuestions}
          />
        </Form>

        <Box>
          <Box p={4}>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
              <Feature
                icon={<Icon as={AiFillProfile} w={10} h={10} />}
                // title={"Our Vision"}
                text={
                  "Embarking on your study journey in Canada? Join our experts for a special program tailored just for you! Get essential insights into Canadian universities, visa details, legal info, and tips for adapting to a new environment."
                }
              />
              <Feature
                icon={<Icon as={AiFillHome} w={10} h={10} />}
                // title={"Our Objective"}
                text={
                  "Discover the role of the campus ministry and the importance of your spiritual growth while studying abroad. Faith and Community is an important part of your journey, and we want to help you find yours."
                }
              />
              <Feature
                icon={<Icon as={AiFillCalendar} w={10} h={10} />}
                // title={"Our Toolbox"}
                text={
                  "There will also be a Q&A session, where you can find out things specific to your case and hear from our community those things they wish they knew before coming to Canada."
                }
              />
            </SimpleGrid>
          </Box>
          <Text
            textAlign="center"
            fontSize="4xl"
            fontWeight={"bold"}
            p={{ base: 2 }}
            maxW={{ base: "90%", md: "90%" }}
            margin={"auto"}
            bg={"gray.50"}
            padding={"2"}
            rounded={"lg"}
          >
            Don't miss it! Register now!
            <Flex
              maxW={"20%"}
              margin={"auto"}
              mt={2}
              color={"project.theme"}
              justifyContent={"center"}
              gap={2}
              fontSize="2xl"
            >
              <Link href="https://www.instagram.com/blwcan.subregion/" target="_blank">
                <Icon as={FaInstagram} />
              </Link>
              <Link href="https://www.tiktok.com/@blwcanada" target="_blank">
                <Icon as={FaTiktok} />
              </Link>
              <Link href="https://www.youtube.com/@lwcanada" target="_blank">
                <Icon as={FaYoutube} />
              </Link>
            </Flex>
          </Text>
        </Box>
        <Box>
          <Heading as="h2" size="xl" textAlign="center" p="2%" m="2%">
            Previous Edition
          </Heading>
          <Center>
            <Box textAlign="center">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/Dvc9vf7XRps?si=tHo-ZszauBH_RH_j"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </Box>
          </Center>
        </Box>
      </Stack>
    </Center>
  );
}
