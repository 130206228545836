// Description: This file contains the endpoint and the options for the fetch
// request. The endpoint is the url of the backend server. The options are
// the headers and the body of the request. The getEndpoint function is used
// to concatenate the endpoint with the url of the request. The getOptions
// function is used to create the options for a request that does not contain
// a file. The getOptionsFile function is used to create the options for a
// request that contains a file
const endpoint = "https://blw-can-api.herokuapp.com/api/";
const getOptions = (dict) => {
  return {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dict),
  };
};
const getOptionsFile = (file) => {
  return {
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    body: file,
  };
};
const getEndpoint = (url) => `${endpoint}${url}`;
export { endpoint, getOptions, getEndpoint, getOptionsFile };
