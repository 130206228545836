import {
  Flex,
  Container,
  Heading,
  Stack,
  Text,
  Button,
  Box,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import lands from "../assets/landskate-min.jpg";


export default function CallToActionWithImageOverlay() {
  const [num, setNum] = useState(true);
  const MILISECOND = 2000;

  useEffect(() => {
    const interval = setInterval(() => {
      setNum(!num);
    }, MILISECOND);

    return () => clearInterval(interval);
  });

  const overlayImage = num ? lands: lands;

  return (
    <Box
      position="relative"
      height={{ sm: "32rem", lg: "40rem" }}
      width="full"
      bgImage={`url(${overlayImage})`}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
    >
      <Container maxW={"5xl"} position="relative" zIndex="2">
        <Stack
          textAlign={"center"}
          align={"center"}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
        >
          <Heading
            color="white"
            fontWeight={600}
            fontSize={{ base: "3xl", sm: "4xl", md: "6xl" }}
            lineHeight={"110%"}
          >
            Impacting lives all across{" "}
            <Text as={"span"} color={"project.theme"}>
              Canada
            </Text>
          </Heading>
          <Text color={"white"} maxW={"3xl"}>
            Giving you purpose through the Word. Showing you the way, the truth,
            and the more excellent life. Discipling young people with the Word of
            God, making them who God has called them to be
          </Text>
          <Stack spacing={6} direction={useBreakpointValue({ base: "column", md: "row" })}>
            <Button
              rounded={"full"}
              px={6}
              colorScheme={"red"}
              bg={"project.theme"}
              _hover={{ bg: "project.theme100" }}
              as="a"
              href="/contact/contact-us"
            >
              Join a fellowship
            </Button>
            <Button rounded={"full"} px={6} as="a" href="/about/blwcanada">
              Learn more
            </Button>
          </Stack>
        </Stack>
      </Container>
      <Box
        position="absolute"
        top="0"
        left="0"
        height="full"
        width="full"
        bg="blackAlpha.600"
      />
    </Box>
  );
}
