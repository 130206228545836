import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import themes from "./themes";
import "@fontsource/inter/400.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(
  <StrictMode>
    <BrowserRouter>
      <ChakraProvider theme={themes}>
        <App />
      </ChakraProvider>
    </BrowserRouter>
  </StrictMode>
);
