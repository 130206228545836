import { extendTheme } from "@chakra-ui/react";

const themes = extendTheme({
  fonts: {
    heading: "Inter",
    body: "Inter",
  },
  colors: {
    project: {
      backgroundDark: "#CEDEFF",
      theme: "#C53030",
      theme50: "#e07e7e",
      light: "#e48f8f",
      theme100: "#a82929",
      background: "#F9F9F9",
      card: "#FFFFFF",
    },
  },
  global: {
    "html, body": {
      backgroundColor: "#F9F9F9",
    },
  },
});
export default themes;

// project: {
//   backgroundDark: "#CEDEFF",
//   theme: "#C53030",
//   theme50: "#e07e7e",
//   light: "#e48f8f",
//   theme100: "#a82929",
//   background: "#F9F9F9",
//   card: "#FFFFFF",
// },

// project: {
//   backgroundDark: "#CEDEFF",
//   theme: "#FABF01",
//   theme50: "#D4AF37",
//   light: "#E5C063",
//   theme100: "#DEAE36",
//   background: "#F9F9F9",
//   card: "#FFFFFF",
// },

// project: {
//   backgroundDark: "#CEDEFF",
//   theme: "#D9926E",
//   theme50: "#C48661",
//   light: "#FDCDB5",
//   theme100: "#C48661",
//   background: "#F9F9F9",
//   card: "#FFFFFF",
// },
