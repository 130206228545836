import { Box, Text, Flex, Image } from "@chakra-ui/react";
import CallToActionWithIllustration from "../components/Hero";
import SimpleThreeColumns from "../components/ThreeRow";
import lands from "../assets/landskate-min.jpg";

export default function Landing() {
  return (
    <>
      <CallToActionWithIllustration />
      <SimpleThreeColumns />
      <Flex w={"full"}>
        <Image
          width={{ sm: "36rem", lg: "60rem" }}
          mt={{ base: 12, sm: 16 }}
          mb={{ base: 12, sm: 16 }}
          src={lands}
          alt="Hero Image"
          margin="auto"
        />
      </Flex>
    </>
  );
}
