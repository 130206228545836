import {
  Box,
  Heading,
  Text,
  Avatar,
  useColorModeValue,
  AspectRatio,
  Image,
  Link,
  Flex,
} from "@chakra-ui/react";

export default function FellowshipCard({
  leader,
  name,
  meetings,
  picture,
  socials,
}) {
  return (
    <Box
      w={{ base: "250px", md: "300px" }}
      h="350px"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="lg"
      bg={useColorModeValue("white", "gray.800")}
      m={1}
    >
      <AspectRatio ratio={16 / 9}>
        <Image src={picture} alt={name} objectFit="cover" />
      </AspectRatio>

      <Flex direction="column" justifyContent="space-between" p="6">
        <Box>
          <Box d="flex" alignItems="baseline">
            <Text
              color={useColorModeValue("teal.600", "teal.200")}
              fontWeight="semibold"
              letterSpacing="wide"
              fontSize="xs"
              textTransform="uppercase"
            >
              {leader}
            </Text>
          </Box>

          <Box
            mt="1"
            fontWeight="semibold"
            as="h4"
            lineHeight="tight"
            isTruncated
          >
            <Heading fontSize="xl">{name}</Heading>
          </Box>
        </Box>

        <Box>
          {meetings.map((child, index) => (
            <DateLocale
              key={index}
              dayTime={child.dayTime}
              location={child.location}
            />
          ))}
        </Box>
        <Box mt={4}>
          {socials.map((social, index) => (
            <Text key={index} fontSize="sm" color="gray.500">
              <Link href={social.link} isExternal color="project.theme">
                {social.name}
              </Link>
            </Text>
          ))}
        </Box>
      </Flex>
    </Box>
  );
}

export const DateLocale = ({ dayTime, location }) => {
  return (
    <Box>
      <Text as="span" fontWeight="semibold">
        {dayTime}
      </Text>{" "}
      at{" "}
      <Text as="span" fontWeight="semibold">
        {location}
      </Text>
    </Box>
  );
};
