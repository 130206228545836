import { Box, Heading, Image, WrapItem, Text } from "@chakra-ui/react";

export default function Video({ info }) {
  return (
    <Box
      borderBottomLeftRadius="md"
      borderBottomRightRadius="md"
      overflow="hidden"
      flex="1"
      textAlign="center"
    >
      <Image
        src={info.thumbnail}
        // width={{ base: "100%", sm: "45%", md: "45%", lg: "30%" }}
      />
      <Heading fontSize="md">{info.name}</Heading>
      <Text>{info.date.slice(4, 17)}</Text>
    </Box>
  );
}
