import {
  Box,
  Text,
  Container,
  Stack,
  Grid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  Button,
  Link,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ReactHlsPlayer from "react-hls-player";
import { getEndpoint, getOptions } from "../api";
import placeholderVideo from "../assets/placeholder.png";
import image from "../assets/spanish.jpg";

import "./style.css";
import Chat from "../components/Chat";
import InputFormat from "../components/InputFormat";

export default function WatchLiveHSLHS() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");

  const loginWithRegistrationEmail = async (e) => {
    e.preventDefault();
    if (name && email && number && country) {
      let packet = {
        name: name,
        email: email,
        number: number,
        country: country,
      };

      const options = getOptions(packet);
      const endpoint = getEndpoint("hslhs_view");

      try {
        const res = await fetch(endpoint, options);
        const data = await res.json();

        if (data === true) {
          onClose(); // Close the modal if email is confirmed
        }
      } catch (error) {
        console.error(error);
        alert("La registración falló, por favor intente de nuevo.");
      }
    } else {
      let missingFields = [];

      if (!name) missingFields.push("Nombre");
      if (!email) missingFields.push("Correo Electrónico");
      if (!number) missingFields.push("Número de Teléfono");
      if (!country) missingFields.push("País");

      alert(
        `Por favor, complete los siguientes campos: ${missingFields.join(", ")}`
      );
    }
  };

  useEffect(() => {
    onOpen(); // This opens the modal when the component mounts
  }, [onOpen]); // Dependency on onOpen ensures the function is called once

  return (
    <Box>
      <Container
        as={Grid}
        maxW="90%"
        templateColumns={{ md: "70% 30%" }}
        gap={2}
        margin={{ base: "0", md: "auto" }}
        py={"2%"}
      >
        <Stack
          bg={"gray.50"}
          rounded={"xl"}
          boxShadow="md"
          py={"2%"}
          align={"center"}
        >
          <ReactHlsPlayer
            src={
              "https://tni-out.cdn.cscloudws.com/translations/spanish/playlist.m3u8"
            }
            autoPlay={true}
            controls={true}
            width="95%"
            height="auto"
            poster={placeholderVideo}
          />
        </Stack>
        <Stack bg={"gray.50"} rounded={"xl"} boxShadow="md">
          <Chat />
        </Stack>
        <Box
          textAlign={"center"}
          backgroundColor={"black"}
          color={"white"}
          py="2%"
        >
          <Text fontWeight={"bold"}>
            Acelera tu fe para la próxima edición de los Servicios de Sanación
            en Vivo de Healing Streams, un evento monumental destinado a reunir
            a miles de millones de personas en todo el mundo para una
            experiencia sobrenatural del amor de Dios, culminando en la sanación
            de los enfermos y milagros extraordinarios. Marca tus calendarios
            para los días tan esperados desde el viernes 27 hasta el domingo 29
            de octubre de 2023.
          </Text>
          <Button
            as={Link}
            fontSize={"xs"}
            fontWeight={600}
            color={"white"}
            bg={"project.theme"}
            onClick={() => {
              const endpoint = getEndpoint("online/hslhs");
              fetch(endpoint).then((res) => res.json());
            }}
            _hover={{
              bg: "project.theme50",
            }}
            m="auto"
            to={"/httn.pdf"}
            target="_blank"
            download="httn.pdf"
          >
            Descargar la revista HTTN
          </Button>
        </Box>
        <Image src={image} margin={"auto"} />
      </Container>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Por favor ingrese sus datos para continuar con el programa
          </ModalHeader>
          <ModalBody>
            <Box as={"form"} onSubmitCapture={loginWithRegistrationEmail}>
              <Stack spacing={1}>
                <InputFormat
                  inputName={"Nombre"}
                  ifApplicable={false}
                  inputType={"text"}
                  inputPlaceholder={"Jose Perez"}
                  inputValue={name}
                  returnFunction={setName}
                />
                <InputFormat
                  inputName={"Número de Teléfono"}
                  ifApplicable={false}
                  inputType={"text"}
                  inputPlaceholder={"123-456-7890"}
                  inputValue={number}
                  returnFunction={setNumber}
                />
                <InputFormat
                  inputName={"Correo Electrónico"}
                  ifApplicable={false}
                  inputType={"email"}
                  inputPlaceholder={"ejemplo@dominio.com"}
                  inputValue={email}
                  returnFunction={setEmail}
                />
                <InputFormat
                  inputName={"Pais"}
                  ifApplicable={false}
                  inputType={"text"}
                  inputPlaceholder={"Nicaragua"}
                  inputValue={country}
                  returnFunction={setCountry}
                />
              </Stack>
              <Button
                fontFamily={"heading"}
                mt={2}
                w={"full"}
                type="submit"
                bgGradient="linear(to-r, project.theme100, project.theme)"
                color={"white"}
                _hover={{
                  bgGradient: "linear(to-r, project.theme, project.theme50)",
                  boxShadow: "xl",
                }}
              >
                Acceso
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
