import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Heading,
  Input,
  Stack,
  Textarea,
  Container,
  SimpleGrid,
  Text,
  Icon,
  useBreakpointValue,
  Image,
} from "@chakra-ui/react";
import { getEndpoint, getOptions } from "../api";
import salvation from "../assets/salvation.mp4";
import ReactHlsPlayer from "react-hls-player";
import prayer from "../assets/about-pastor.jpg";
import JoinUs from "./JoinUs";

export default function AboutPastorChris() {
  const [type, setType] = useState("salvation");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const newMessage = async () => {
    if (name && email && message && type) {
      let packet = {
        message: message,
        type: type,
        name: name,
        email: email,
      };

      const options = getOptions(packet);
      const endpoint = getEndpoint("contact");

      try {
        const res = await fetch(endpoint, options);
        const data = await res.json();
      } catch (error) {
        console.log(error);
        alert("Message not sent. Please try again");
      }
    } else {
      if (!type) {
        alert("Put in your type first");
      } else if (!message) {
        alert("Put in your message first");
      } else if (!name) {
        alert("Put in your name first");
      } else if (!email) {
        alert("Put in your email first");
      }
    }
  };

  return (
    <Box position={"relative"}>
      <Container
        as={SimpleGrid}
        maxW={"7xl"}
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 5, lg: 24 }}
        py={{ base: 5, sm: 10, lg: 16 }}
      >
        <Stack spacing={{ base: 10, md: 20 }}>
          <Image
            textAlign={useBreakpointValue({ base: "center", md: "left" })}
            src={prayer}
            borderRadius="md"
          />
        </Stack>
        <Stack
          bg={"gray.50"}
          rounded={"xl"}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: "lg" }}
        >
          <Stack spacing={4}>
            <Heading
              color="project.theme"
              lineHeight={1.1}
              fontSize={{ base: "1xl", sm: "2xl", md: "3xl" }}
              textAlign="center"
            >
              About Pastor Chris
            </Heading>
            <Text color={"gray.900"} fontSize={"md"} textAlign="center">
              One of the best things in life is to have a good life coach, and
              here in the BLW Campus Ministry, we have the best mentor and life
              coach that anyone can have. We are blessed in the BLW Campus
              Ministry to have the best life coach the world has ever had; to be
              raised and mentored by the best, Rev. Dr. Chris Oyakhilome Dsc.
              Dd.
              <Text
                as="span"
                fontWeight="bold"
                color="project.theme"
                display="block"
                padding="10px"
              ></Text>
              Pastor Chris Oyakhilome (D.Sc., D.D.), is the President of
              Loveworld Inc. with a unique minister of the gospel of Jesus
              Christ, his Spirit-filled and anointed leadership continues to
              propel a dynamic, multifaceted and global ministry.
              <Text
                as="span"
                fontWeight="bold"
                color="project.theme"
                display="block"
                padding="10px"
              ></Text>
              As a pastor, teacher, healing minister, television host and
              best-selling author, Pastor Chris daily exudes an insatiable
              passion to reach the peoples of the world with God’s manifested
              presence; a divine commission he has diligently focused on
              fulfilling for more than 30 years.
            </Text>
          </Stack>
        </Stack>
      </Container>
      <JoinUs />
      <Blur
        position={"absolute"}
        top={-10}
        left={-10}
        style={{ filter: "blur(70px)" }}
        zIndex="-10"
      />
    </Box>
  );
}

export const Blur = (props: IconProps) => {
  return (
    <Icon
      width={useBreakpointValue({ base: "100%", md: "40vw", lg: "30vw" })}
      zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
      height="560px"
      viewBox="0 0 528 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="71" cy="61" r="111" fill="#F56565" />
      <circle cx="244" cy="106" r="139" fill="#ED64A6" />
      <circle cy="291" r="139" fill="#ED64A6" />
      <circle cx="80.5" cy="189.5" r="101.5" fill="#ED8936" />
      <circle cx="196.5" cy="317.5" r="101.5" fill="#ECC94B" />
      <circle cx="70.5" cy="458.5" r="101.5" fill="#48BB78" />
      <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1" />
    </Icon>
  );
};
