import calgary from "./calgary.jpg";
import carleton from "./carleton.jpeg";
import durham from "./durham.jpg";
import lacite from "./la-cite.jpg";
import lethbridge from "./lethbridge.jpg";
import manitoba from "./manitoba.jpg";
import memorial from "./memorial.jpg";
import nic from "./nic.jpg";
import ottawa from "./ottawa.jpeg";
import tmu from "./tmu.jpeg";
import utm from "./utm.jpeg";
import winnipeg from "./winnipeg.jpeg";
import york from "./york.jpg";
import sample from "./sample.png";

const images = {
  calgary,
  carleton,
  durham,
  lacite,
  lethbridge,
  manitoba,
  memorial,
  nic,
  ottawa,
  tmu,
  utm,
  winnipeg,
  york,
  sample,
};

const carousel = [
  calgary,
  carleton,
  durham,
  lacite,
  lethbridge,
  manitoba,
  memorial,
  nic,
  ottawa,
  tmu,
  utm,
  winnipeg,
  york,
  sample,
];

export { images, carousel };
