import { useState } from "react";
import { parse } from "papaparse";
import { Box, Text, Input, Link } from "@chakra-ui/react";
import skate from "../assets/soul_records.csv";

export default function DropFiles({ returnFunction }) {
  const [highlighted, setHighlighted] = useState(false);
  const [data, setData] = useState([]);

  return (
    <Box textAlign={"center"}>
      <Text fontWeight={"bold"}>Mass Upload Contacts</Text>
      <Box
        border={`${highlighted ? "2px solid #D9926E" : "2px solid gray"}`}
        borderRadius={"md"}
        maxW="80%"
        padding={3}
        margin={"auto"}
        onDragEnter={() => {
          setHighlighted(true);
        }}
        onDragLeave={() => {
          setHighlighted(false);
        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={(e) => {
          e.preventDefault();
          setHighlighted(false);

          Array.from(e.dataTransfer.files)
            .filter((file) => file.type === "text/csv")
            .forEach(async (file) => {
              const text = await file.text();
              const result = parse(text, { header: true });
              setData((existing) => [...existing, ...result.data]);
            });

          returnFunction(data);
        }}
      >
        Drag and drop, or choose file
        <Input
          type={"file"}
          padding={1}
          margin={1}
          onInputCapture={(e) => {
            e.preventDefault();
            console.log(e);

            Array.from(e.target.files)
              .filter((file) => file.type === "text/csv")
              .forEach(async (file) => {
                const text = await file.text();
                const result = parse(text, { header: true });
                setData((existing) => [...existing, ...result.data]);
              });

            returnFunction(data);
          }}
        />
      </Box>

      <Link href={skate} download color="teal.500">
        Click to download and fill in sample spreadsheet
      </Link>

      <ul>
        {data.map((contact) => (
          <li>
            <strong>{contact.name}</strong>: {contact.fellowship}
          </li>
        ))}
      </ul>
    </Box>
  );
}
