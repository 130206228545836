import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Heading,
  Input,
  Stack,
  Textarea,
  useBreakpointValue,
  Container,
  SimpleGrid,
  Image,
  Text,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { getEndpoint, getOptions } from "../api";
import bench from "../assets/bench-look.JPEG";
import prayer from "../assets/prayer.jpeg";

export default function ContactComponent() {
  let { code } = useParams();

  const [type, setType] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [data, setData] = useState({});

  useEffect(() => {
    setData(MESSAGES.find((x) => x.code === code));
    setType(MESSAGES.find((x) => x.code === code).type);
  }, []);

  const newMessage = async (e) => {
    e.preventDefault();
    if (name && email && message && type) {
      let packet = {
        message: message,
        type: type,
        name: name,
        email: email,
      };

      const options = getOptions(packet);
      const endpoint = getEndpoint("contact");

      try {
        const res = await fetch(endpoint, options);
        const data = await res.json();

        if (data == "message sent") {
          alert(data);
          window.location.reload(false);
        }
      } catch (error) {
        console.log(error);
        alert("Message not sent. Please try again");
      }
    } else {
      if (!type) {
        alert("Put in your type first");
      } else if (!message) {
        alert("Put in your message first");
      } else if (!name) {
        alert("Put in your name first");
      } else if (!email) {
        alert("Put in your email first");
      }
    }
  };

  return (
    <Box position={"relative"}>
      <Container
        as={SimpleGrid}
        maxW={"7xl"}
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 5, lg: 24 }}
        py={{ base: 5, sm: 10, lg: 16 }}
      >
        <Stack spacing={{ base: 10, md: 20 }}>
          <Image
            textAlign={useBreakpointValue({ base: "center", md: "left" })}
            src={data.picture}
            borderRadius="md"
          />
        </Stack>
        <Stack
          bg={"gray.50"}
          rounded={"xl"}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: "lg" }}
        >
          <Stack spacing={4}>
            <Heading
              color={"gray.800"}
              lineHeight={1.1}
              fontSize={{ base: "1xl", sm: "2xl", md: "3xl" }}
            >
              {data.page}
            </Heading>
            <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
              {data.message}
            </Text>
          </Stack>
          <Box
            as={"form"}
            mt={10}
            onSubmitCapture={(e) => {
              newMessage(e);
            }}
          >
            <Stack spacing={4}>
              <Input
                placeholder="Your full name"
                type="text"
                bg={"gray.100"}
                border={0}
                color={"black"}
                _placeholder={{
                  color: "gray.500",
                }}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <Input
                placeholder="example@gmail.com"
                type="email"
                bg={"gray.100"}
                border={0}
                color={"black"}
                _placeholder={{
                  color: "gray.500",
                }}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <Textarea
                placeholder={data.textbox}
                bg={"gray.100"}
                border={0}
                color={"black"}
                _placeholder={{
                  color: "gray.500",
                }}
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
            </Stack>

            <Button
              fontFamily={"heading"}
              mt={8}
              w={"full"}
              bgGradient="linear(to-r, project.theme100, project.theme)"
              color={"white"}
              _hover={{
                bgGradient: "linear(to-r, project.theme, project.theme50)",
                boxShadow: "xl",
              }}
              onClick={(e) => {
                newMessage(e);
              }}
            >
              Send Message
            </Button>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}

const MESSAGES: Array<NavItem> = [
  {
    page: "Send a Prayer Request",
    message:
      "Would you like us to pray with you? Kindly fill the form and we'll get back to you shortly.",
    textbox: "Send a prayer request",
    code: "prayer-request",
    type: "prayer request",
    picture: prayer,
  },
  {
    page: "Send us a message",
    message: "Get in contact with us now",
    textbox: "Send message",
    code: "contact-us",
    type: "contact us",
    picture: bench,
  },
];
