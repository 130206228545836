import {
  Box,
  chakra,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
  Input,
  IconButton,
  useColorModeValue,
  Image,
  useBreakpointValue,
  Button,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { BiMailSend } from "react-icons/bi";
import logo from "../assets/can-logo.png";

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode,
  label: string,
  href: string,
}) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

export default function LargeWithNewsletter() {
  let pack;
  if (sessionStorage.getItem("pack")) {
    pack = (
      <Link
        href="/"
        fontFamily={"heading"}
        fontWeight={"bold"}
        padding={"5px"}
        mt={8}
        w={"50%"}
        borderRadius={"md"}
        bgGradient="linear(to-r, project.theme100, project.theme)"
        color={"white"}
        _hover={{
          bgGradient: "linear(to-r, project.theme, project.theme50)",
          boxShadow: "xl",
        }}
        onClick={() => {
          sessionStorage.removeItem("pack");
          sessionStorage.removeItem("reloaded");
        }}
      >
        Sign Out
      </Link>
    );
  }
  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
      textAlign="center"
    >
      <Container as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid
          templateColumns={{ sm: "1fr 1fr", md: "1fr 1fr" }}
          spacing={8}
        >
          <Stack spacing={6}>
            <Box>
              <Link href={"/"}>
                <Image
                  textAlign={useBreakpointValue({ base: "center", md: "left" })}
                  src={logo}
                  // maxW="100px"
                />
              </Link>
            </Box>
            <Text fontSize={"sm"}>© 2024 BLW Canada. All rights reserved</Text>
            <Stack direction={"row"} spacing={6} justify="center">
              {/* <SocialButton label={"Twitter"} href={"#"}>
                <FaTwitter />
              </SocialButton>
              <SocialButton label={"YouTube"} href={"#"}>
                <FaYoutube />
              </SocialButton> */}
              <SocialButton
                label={"Instagram"}
                href={"https://www.instagram.com/blwcan.subregion/"}
              >
                <FaInstagram />
              </SocialButton>
            </Stack>
          </Stack>
          {/* <Stack align={{ sm: "center", md: "flex-start" }}>
            <ListHeader>Company</ListHeader>
            <Link href={"#"}>About us</Link>
            <Link href={"#"}>Blog</Link>
            <Link href={"#"}>Contact us</Link>
            <Link href={"#"}>Pricing</Link>
            <Link href={"#"}>Testimonials</Link>
          </Stack>
          <Stack align={{ sm: "center", md: "flex-start" }}>
            <ListHeader>Support</ListHeader>
            <Link href={"#"}>Help Center</Link>
            <Link href={"#"}>Terms of Service</Link>
            <Link href={"#"}>Legal</Link>
            <Link href={"#"}>Privacy Policy</Link>
            <Link href={"#"}>Satus</Link>
          </Stack> */}
          <Stack align={"center"}>
            <ListHeader>Stay up to date</ListHeader>
            <Stack direction={"row"} pb={8}>
              <Input
                placeholder={"Your email address"}
                bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
                border={0}
                _focus={{
                  bg: "whiteAlpha.300",
                }}
              />
              <IconButton
                bg={useColorModeValue("project.theme", "project.theme100")}
                color={useColorModeValue("white", "gray.800")}
                _hover={{
                  bg: "project.theme100",
                }}
                aria-label="Subscribe"
                icon={<BiMailSend />}
              />
            </Stack>
            <ListHeader>Links</ListHeader>
            <Link href={"/contact/contact-us"}>Contact Us</Link>
            <Link href={"/watch-live"}>Videos</Link>
            <ListHeader></ListHeader>
            {pack}
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
}
