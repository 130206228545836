import { Box, Text, Container, Stack, Grid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ReactHlsPlayer from "react-hls-player";
import { useNavigate } from "react-router-dom";
import { getEndpoint } from "../api";
import placeholderVideo from "../assets/placeholder.png";

import "./style.css";
import Chat from "../components/Chat";

export default function WatchLive() {
  const [link, setLink] = useState("");

  let navigate = useNavigate();

  //Check if user is logged in
  const checkLogin = () => {
    const pack = sessionStorage.getItem("pack");
    if (pack) {
      return true;
    } else {
      alert("You must be logged in to watch live");
      return navigate("/login");
    }
  };

  const getStreamLink = () => {
    const endpoint = getEndpoint("webtv/stream");
    fetch(endpoint)
      .then((res) => res.json())
      .then((res) => {
        setLink(res);
      });
  };

  useEffect(() => {
    getStreamLink();
    checkLogin();
  }, []);

  return (
    <Box>
      <Container
        as={Grid}
        maxW="90%"
        templateColumns={{ md: "70% 30%" }}
        gap={2}
        padding="2%"
      >
        <Stack
          spacing={{ base: 2 }}
          bg={"gray.50"}
          rounded={"xl"}
          p={{ base: 4 }}
          boxShadow="md"
        >
          <ReactHlsPlayer
            src={
              "https://live.blwcmofficialdeployment.com:8080/hls/bcanadagrp/EpLeyEtOpaSh.m3u8"
            }
            autoPlay={true}
            controls={true}
            width="100%"
            height="auto"
            poster={placeholderVideo}
          />
          {/* <Box position="relative" overflow="hidden" paddingTop="56.25%">
            <iframe
              class="resp-iframe"
              poster={placeholderVideo}
              src={link}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Box> */}
        </Stack>
        <Stack
          bg={"gray.50"}
          rounded={"xl"}
          p={{ base: 4 }}
          spacing={{ base: 2 }}
          boxShadow="md"
        >
          <Chat />
        </Stack>
      </Container>
    </Box>
  );
}
