import { Box, Text, Select } from "@chakra-ui/react";

export default function InputCheckboxFormat({
  inputName,
  ifApplicable,
  children,
  inputValue,
  returnFunction,
}) {
  return (
    <Box>
      <Text>
        {inputName}
        <Text as="span" color="red">
          {" "}
          {ifApplicable ? "*" : ""}
        </Text>
      </Text>
      <Select
        inputValue={inputValue}
        onChange={(e) => {
          returnFunction(e.target.value);
        }}
        bg={"gray.100"}
        border={0}
        color={inputValue ? "black" : "gray"}
        _placeholder={{
          color: "gray.500",
        }}
      >
        {children}
      </Select>
    </Box>
  );
}
