import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Heading,
  Input,
  Stack,
  Textarea,
  Container,
  SimpleGrid,
  Text,
  Icon,
  useBreakpointValue,
} from "@chakra-ui/react";
import { getEndpoint, getOptions } from "../api";
import salvation from "../assets/salvation.mp4";
import ReactHlsPlayer from "react-hls-player";

export default function PrayerSalvation() {
  const [type, setType] = useState("salvation");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const newMessage = async (e) => {
    e.preventDefault();
    if (name && email && message && type) {
      let packet = {
        message: message,
        type: type,
        name: name,
        email: email,
      };

      const options = getOptions(packet);
      const endpoint = getEndpoint("contact");

      try {
        const res = await fetch(endpoint, options);
        const data = await res.json();
      } catch (error) {
        console.log(error);
        alert("Message not sent. Please try again");
      }
    } else {
      if (!type) {
        alert("Put in your type first");
      } else if (!message) {
        alert("Put in your message first");
      } else if (!name) {
        alert("Put in your name first");
      } else if (!email) {
        alert("Put in your email first");
      }
    }
  };

  return (
    <Box position={"relative"}>
      <Container
        as={SimpleGrid}
        maxW={"7xl"}
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 5, lg: 24 }}
        py={{ base: 5, sm: 10, lg: 16 }}
      >
        <Stack spacing={{ base: 10, md: 20 }}>
          <video
            src={salvation}
            autoPlay={false}
            controls={true}
            width="100%"
            height="auto"
          />
          <Text
            color={"gray.500"}
            fontSize={{ base: "sm", sm: "md" }}
            textAlign="center"
          >
            <Text as="span" fontWeight="bold" color="project.theme">
              WE INVITE YOU TO MAKE JESUS CHRIST THE LORD OF YOUR LIFE BY SAYING
              THESE WORDS OF PRAYER:
            </Text>
            <br />
            “Oh Lord God, I come to you in the name of Jesus Christ. Your word
            says, “…that whosoever shall call upon the name of the Lord shall be
            saved”. I believe in Jesus Christ, the Son of the living God. I
            believe Jesus died for me and that he was buried and raised from the
            dead. Right now, I confess with my mouth that Jesus Christ is Lord
            of my life. I receive by faith eternal life into my spirit. Thank
            you lord for saving my soul. I now have Christ dwelling in me. I am
            saved, I am born again, I am a new creation. Halleluyah!
          </Text>
        </Stack>
        <Stack
          bg={"gray.50"}
          rounded={"xl"}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: "lg" }}
        >
          <Stack spacing={4}>
            <Heading
              color={"gray.800"}
              lineHeight={1.1}
              fontSize={{ base: "1xl", sm: "2xl", md: "3xl" }}
            >
              Recieve Salvation
            </Heading>
          </Stack>
          <Box
            as={"form"}
            mt={10}
            onSubmitCapture={(e) => {
              newMessage(e);
            }}
          >
            <Stack spacing={4}>
              <Input
                placeholder="Your full name"
                type="text"
                bg={"gray.100"}
                border={0}
                color={"black"}
                _placeholder={{
                  color: "gray.500",
                }}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <Input
                placeholder="example@gmail.com"
                type="email"
                bg={"gray.100"}
                border={0}
                color={"black"}
                _placeholder={{
                  color: "gray.500",
                }}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <Textarea
                placeholder="Wow, welcome to the family. Get in touch with us now, lets guide you in your next steps"
                bg={"gray.100"}
                border={0}
                color={"black"}
                _placeholder={{
                  color: "gray.500",
                }}
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
            </Stack>

            <Button
              fontFamily={"heading"}
              mt={8}
              w={"full"}
              bgGradient="linear(to-r, project.theme100, project.theme)"
              color={"white"}
              _hover={{
                bgGradient: "linear(to-r, project.theme, project.theme50)",
                boxShadow: "xl",
              }}
              onClick={(e) => {
                newMessage(e);
              }}
            >
              Send Message
            </Button>
          </Box>
        </Stack>
      </Container>
      <Blur
        position={"absolute"}
        top={-10}
        left={-10}
        style={{ filter: "blur(70px)" }}
        zIndex="-10"
      />
    </Box>
  );
}

export const Blur = (props: IconProps) => {
  return (
    <Icon
      width={useBreakpointValue({ base: "100%", md: "40vw", lg: "30vw" })}
      zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
      height="560px"
      viewBox="0 0 528 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="71" cy="61" r="111" fill="#F56565" />
      <circle cx="244" cy="106" r="139" fill="#ED64A6" />
      <circle cy="291" r="139" fill="#ED64A6" />
      <circle cx="80.5" cy="189.5" r="101.5" fill="#ED8936" />
      <circle cx="196.5" cy="317.5" r="101.5" fill="#ECC94B" />
      <circle cx="70.5" cy="458.5" r="101.5" fill="#48BB78" />
      <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1" />
    </Icon>
  );
};
