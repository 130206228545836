import { useState } from "react";
import {
  Box,
  Button,
  Heading,
  Input,
  Stack,
  useBreakpointValue,
  Container,
  Text,
} from "@chakra-ui/react";
import { getEndpoint, getOptions } from "../api";

export default function URLShortenerComponent() {
  const [originalUrl, setOriginalUrl] = useState("");
  const [name, setName] = useState("");
  const [shortenedUrl, setShortenedUrl] = useState("");

  const shortenUrl = async (e) => {
    e.preventDefault();
    if (originalUrl && name) {
      let packet = {
        url: originalUrl,
        name: name,
      };

      const options = getOptions(packet);
      const endpoint = getEndpoint("shorten");

      try {
        const res = await fetch(endpoint, options);
        const data = await res.json();

        if (typeof data === "string") {
          alert(data);
        } else {
          setShortenedUrl(data.link);
          alert("URL shortened successfully!");
        }
      } catch (error) {
        console.log(error);
        alert("Error shortening URL. Please try again");
      }
    } else {
      if (!originalUrl) {
        alert("Please enter the original URL");
      } else if (!name) {
        alert("Please enter a name for the shortened URL");
      }
    }
  };

  return (
    <Box position={"relative"}>
      <Container
        maxW={"7xl"}
        py={{ base: 5, sm: 10, lg: 16 }}
      >
        <Stack
          bg={"gray.50"}
          rounded={"xl"}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: "lg" }}
        >
          <Heading
            color={"gray.800"}
            lineHeight={1.1}
            fontSize={{ base: "1xl", sm: "2xl", md: "3xl" }}
          >
            URL Shortener
          </Heading>
          <Box
            as={"form"}
            mt={10}
            onSubmitCapture={(e) => {
              shortenUrl(e);
            }}
          >
            <Stack spacing={4}>
              <Input
                placeholder="Enter the original URL"
                type="text"
                bg={"gray.100"}
                border={0}
                color={"black"}
                _placeholder={{
                  color: "gray.500",
                }}
                value={originalUrl}
                onChange={(e) => {
                  setOriginalUrl(e.target.value);
                }}
              />
              <Input
                placeholder="Enter a name for the shortened URL"
                type="text"
                bg={"gray.100"}
                border={0}
                color={"black"}
                _placeholder={{
                  color: "gray.500",
                }}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Stack>

            <Button
              fontFamily={"heading"}
              mt={8}
              w={"full"}
              bgGradient="linear(to-r, project.theme100, project.theme)"
              color={"white"}
              _hover={{
                bgGradient: "linear(to-r, project.theme, project.theme50)",
                boxShadow: "xl",
              }}
              onClick={(e) => {
                shortenUrl(e);
              }}
            >
              Shorten URL
            </Button>
          </Box>
          {shortenedUrl && (
            <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }} mt={4}>
              Shortened URL: {shortenedUrl}
            </Text>
          )}
        </Stack>
      </Container>
    </Box>
  );
}
