import { Box, Text, Stack, Input, Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getEndpoint, getOptions } from "../api";
import { useUser } from "../context/UserContext";

export default function Chat() {
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const MILISECOND = 30000;
  const { user } = useUser();

  const sendComment = async (e) => {
    e.preventDefault();
    if (comment) {
      let packet = {
        comment: comment,
        person: user.name ? user.name : "Participant",
      };

      const options = getOptions(packet);
      const endpoint = getEndpoint("webtv/add-chat");

      try {
        const res = await fetch(endpoint, options);
        const data = await res.json();

        getComments();
        return setComment("");
      } catch (error) {
        console.log(error);
        alert("Comment not sent");
      }
    } else {
      if (!comment) {
        alert("Type your comment");
      }
    }
  };

  const getComments = async () => {
    const endpoint = getEndpoint("webtv/comments");

    try {
      const res = await fetch(endpoint);
      const data = await res.json();
      data.reverse();
      setComments(data);
    } catch (error) {
      console.log(error);
      console.log("fetch failed, " + endpoint);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getComments();
    }, MILISECOND);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    getComments();
  }, []);

  return (
    <Stack textAlign="center">
      <Text fontWeight="bold" fontSize="18px">
        COMMENTS
      </Text>
      <Box
        background="project.backgroundDark"
        padding={{ base: 2 }}
        borderRadius="md"
        overflow="scroll"
        maxH="350px"
      >
        {comments.map((comment) => {
          return <Comment info={comment} />;
        })}
      </Box>
      <Stack
        as={"form"}
        align="center"
        onSubmitCapture={(e) => {
          sendComment(e);
        }}
      >
        <Input
          placeholder="Type your comment..."
          fontSize="12px"
          type="text"
          bg={"gray.100"}
          border={0}
          color={"black"}
          _placeholder={{
            color: "gray.500",
          }}
          value={comment}
          onChange={(e) => {
            setComment(e.target.value);
          }}
        />
        <Button
          fontFamily={"heading"}
          type="submit"
          bgGradient="linear(to-r, project.theme100, project.theme)"
          color={"white"}
          margin="auto"
          _hover={{
            bgGradient: "linear(to-r, project.theme, project.theme)",
            boxShadow: "md",
          }}
          onClick={(e) => {
            sendComment(e);
          }}
        >
          Send
        </Button>
      </Stack>
    </Stack>
  );
}

export const Comment = ({ info }) => {
  return (
    <Box
      background="project.background"
      margin={{ base: 1 }}
      padding={{ base: 1 }}
      borderRadius="md"
    >
      <Text fontSize="normal" fontWeight="410">
        {info.person}
      </Text>
      <Text fontSize={{ base: "0.9rem", md: "normal" }}>{info.comment}</Text>
      {/* <Text fontWeight="300" fontSize={{ base: "0.7rem", md: "normal" }}>
        {info.date}
      </Text> */}
    </Box>
  );
};
