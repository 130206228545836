import { ReactElement } from "react";
import { Box, SimpleGrid, Icon, Text, Stack, Flex } from "@chakra-ui/react";
import { AiFillCalendar, AiFillEye, AiFillProfile } from "react-icons/ai";

interface FeatureProps {
  title: string;
  text: string;
  icon: ReactElement;
}

const Feature = ({ title, text, icon }: FeatureProps) => {
  return (
    <Stack textAlign="center">
      <Flex
        w={16}
        h={16}
        align={"center"}
        justify={"center"}
        color={"project.theme"}
        rounded={"full"}
        bg={"gray.100"}
        mb={1}
        margin="auto"
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text color={"gray.600"}>{text}</Text>
    </Stack>
  );
};

export default function SimpleThreeColumns() {
  return (
    <Box p={4}>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
        <Feature
          icon={<Icon as={AiFillEye} w={10} h={10} />}
          title={"Our Vision"}
          text={
            "Our vision is to take God’s divine presence to the peoples and nations of the world, demonstrating the character of the spirit."
          }
        />
        <Feature
          icon={<Icon as={AiFillProfile} w={10} h={10} />}
          title={"Our Objective"}
          text={
            "To reach every generation of youth on Campuses and communities around the world, penetrating their eco-systems to shape the future with our gospel."
          }
        />
        <Feature
          icon={<Icon as={AiFillCalendar} w={10} h={10} />}
          title={"Our Toolbox"}
          text={
            "The ministry of the Holy Spirit, the Word of God, and prayer. By these three we build ourselves up, fully equipped for the work ahead"
          }
        />
      </SimpleGrid>
    </Box>
  );
}
