import {
  Box,
  Text,
  Container,
  Stack,
  Grid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  Button,
  Link,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ReactHlsPlayer from "react-hls-player";
import { getEndpoint } from "../api";
import placeholderVideo from "../assets/placeholder.png";
import { Link as ReactRouterLink } from "react-router-dom";

import "./style.css";
import Chat from "../components/Chat";

export default function WatchLiveCTC() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [email, setEmail] = useState("");

  const loginWithRegistrationEmail = async (e) => {
    e.preventDefault();
    if (email) {
      const endpoint = getEndpoint(`program_log/${email}`);

      try {
        const res = await fetch(endpoint);
        const data = await res.json();

        if (data === false) {
          alert("Registration not found, try again or register");
        } else {
          onClose(); // Close the modal if email is confirmed
        }
      } catch (error) {
        console.log(error);
        alert("Login failed, please try again");
      }
    } else {
      if (!email) {
        alert("Put in your email first");
      }
    }
  };

  useEffect(() => {
    onOpen(); // This opens the modal when the component mounts
  }, [onOpen]); // Dependency on onOpen ensures the function is called once

  return (
    <Box>
      <Container
        as={Grid}
        maxW="90%"
        templateColumns={{ md: "70% 30%" }}
        gap={2}
        padding="2%"
      >
        <Stack
          spacing={{ base: 2 }}
          bg={"gray.50"}
          rounded={"xl"}
          p={{ base: 4 }}
          boxShadow="md"
        >
          <ReactHlsPlayer
            src={
              "https://live.blwcmofficialdeployment.com:8080/hls/bcanadagrp/EpLeyEtOpaSh.m3u8"
            }
            autoPlay={true}
            controls={true}
            width="100%"
            height="auto"
            poster={placeholderVideo}
          />
        </Stack>
        <Stack
          bg={"gray.50"}
          rounded={"xl"}
          p={{ base: 4 }}
          spacing={{ base: 2 }}
          boxShadow="md"
        >
          <Chat />
        </Stack>
      </Container>

      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Email Confirmation</ModalHeader>
          <ModalBody>
            <p>Please Login with email that was used to register</p>
            <Box
              as={"form"}
              mt={2}
              onSubmitCapture={loginWithRegistrationEmail}
            >
              <Stack spacing={1}>
                <Input
                  placeholder="example@gmail.com"
                  type="email"
                  bg={"gray.100"}
                  border={0}
                  color={"black"}
                  _placeholder={{
                    color: "gray.500",
                  }}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Stack>
              <Button
                fontFamily={"heading"}
                mt={2}
                w={"full"}
                type="submit"
                bgGradient="linear(to-r, project.theme100, project.theme)"
                color={"white"}
                _hover={{
                  bgGradient: "linear(to-r, project.theme, project.theme50)",
                  boxShadow: "xl",
                }}
              >
                Login
              </Button>
              <Box pt={4}>
                <Link as={ReactRouterLink} to="/ctc">
                  <Text color={"blue"} as={"span"}>
                    If you didn't register, click here to do so
                  </Text>
                </Link>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
