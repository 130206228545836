import {
  Box,
  Stack,
  Heading,
  Text,
  Container,
  Input,
  Button,
  SimpleGrid,
  useBreakpointValue,
  IconProps,
  Icon,
  Image,
  Link,
} from "@chakra-ui/react";
import { useState } from "react";
import { getEndpoint, getOptions } from "../api";
import skate from "../assets/skate2-min.jpg";
import { useNavigate, Link as ReactRouterLink } from "react-router-dom";

export default function Register() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [campus, setCampus] = useState("");
  let history = useNavigate();

  const newUser = async (e) => {
    e.preventDefault();
    if (name && email && campus) {
      let packet = {
        campus: campus,
        name: name,
        email: email,
      };

      const options = getOptions(packet);
      const endpoint = getEndpoint("register");

      try {
        const res = await fetch(endpoint, options);
        const data = await res.json();

        sessionStorage.setItem("pack", JSON.stringify(packet));

        return history("/watch-live");
      } catch (error) {
        console.log(error);
        alert("Registration failed, please try again");
      }
    } else {
      if (!campus) {
        alert("Put in your campus first");
      } else if (!name) {
        alert("Put in your name first");
      } else if (!email) {
        alert("Put in your email first");
      }
    }
  };
  return (
    <Box position={"relative"}>
      <Container
        as={SimpleGrid}
        maxW={"7xl"}
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 5, lg: 24 }}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Stack spacing={{ base: 10, md: 20 }}>
          <Image
            textAlign={useBreakpointValue({ base: "center", md: "left" })}
            src={skate}
            borderRadius="md"
          />
        </Stack>
        <Stack
          bg={"gray.50"}
          rounded={"xl"}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: "lg" }}
        >
          <Stack spacing={4}>
            <Heading
              color={"gray.800"}
              lineHeight={1.1}
              fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
            >
              Registration
            </Heading>
            <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
              Get acess to life transforming programming!
            </Text>
          </Stack>
          <Box
            as={"form"}
            mt={10}
            onSubmitCapture={(e) => {
              newUser(e);
            }}
          >
            <Stack spacing={4}>
              <Input
                placeholder="Full Name"
                type="text"
                bg={"gray.100"}
                border={0}
                color={"black"}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                _placeholder={{
                  color: "gray.500",
                }}
              />
              <Input
                placeholder="example@gmail.com"
                type="email"
                bg={"gray.100"}
                border={0}
                color={"black"}
                _placeholder={{
                  color: "gray.500",
                }}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <Input
                placeholder="Your School e.g. York University"
                type="text"
                bg={"gray.100"}
                border={0}
                color={"black"}
                _placeholder={{
                  color: "gray.500",
                }}
                value={campus}
                onChange={(e) => {
                  setCampus(e.target.value);
                }}
              />
            </Stack>
            <Button
              fontFamily={"heading"}
              mt={8}
              w={"full"}
              bgGradient="linear(to-r, project.theme100, project.theme)"
              color={"white"}
              _hover={{
                bgGradient: "linear(to-r, project.theme, project.theme50)",
                boxShadow: "xl",
              }}
              onClick={(e) => {
                newUser(e);
              }}
            >
              Register
            </Button>
            <Box pt={4}>
              <Link as={ReactRouterLink} to="/login">
                <Text color={"blue"} as={"span"}>
                  If you already have an account, click to login
                </Text>
              </Link>
            </Box>
          </Box>
        </Stack>
      </Container>
      <Blur
        position={"absolute"}
        top={-10}
        left={-10}
        style={{ filter: "blur(70px)" }}
        zIndex="-10"
      />
    </Box>
  );
}

export const Blur = (props: IconProps) => {
  return (
    <Icon
      width={useBreakpointValue({ base: "100%", md: "40vw", lg: "30vw" })}
      zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
      height="560px"
      viewBox="0 0 528 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="71" cy="61" r="111" fill="#F56565" />
      <circle cx="244" cy="106" r="139" fill="#ED64A6" />
      <circle cy="291" r="139" fill="#ED64A6" />
      <circle cx="80.5" cy="189.5" r="101.5" fill="#ED8936" />
      <circle cx="196.5" cy="317.5" r="101.5" fill="#ECC94B" />
      <circle cx="70.5" cy="458.5" r="101.5" fill="#48BB78" />
      <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1" />
    </Icon>
  );
};
