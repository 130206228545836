import {
  Box,
  Stack,
  Heading,
  Button,
  Text,
  Center,
  Link,
} from "@chakra-ui/react";
import ImageGrid from "./ImageGrid";
import LinkLogo from "./LinkLogo";

export default function Form({
  name,
  submitFunction,
  submitText,
  children,
  optionalEntries,
  text,
  force,
  link,
  info,
}) {
  if (force) {
    return (
      <Center padding={2}>
        <Stack
          bg={"gray.50"}
          rounded={"xl"}
          p={{ base: 2 }}
          spacing={{ base: 2 }}
          maxW={{ lg: "lg" }}
        >
          <Stack spacing={4}>
            <Heading
              color={"gray.800"}
              lineHeight={1.1}
              textAlign="center"
              fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
            >
              {name}
            </Heading>
          </Stack>
          <Text textAlign="center">{text}</Text>
          <Box as={"form"} mt={2}>
            <Button
              fontFamily={"heading"}
              mt={2}
              w={"full"}
              type="submit"
              bgGradient="linear(to-r, project.theme100, project.theme)"
              color={"white"}
              _hover={{
                bgGradient: "linear(to-r, project.theme, project.theme50)",
                boxShadow: "xl",
              }}
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload Form
            </Button>
          </Box>
        </Stack>
      </Center>
    );
  } else {
    return (
      <Center padding={2}>
        <Stack
          bg={"gray.50"}
          rounded={"xl"}
          p={{ base: 2 }}
          spacing={{ base: 2 }}
          maxW={{ lg: "lg" }}
        >
          <Stack spacing={4}>
            <Heading
              color={"gray.800"}
              lineHeight={1.1}
              textAlign="center"
              fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
            >
              {name}
            </Heading>
          </Stack>
          <Box
            as={"form"}
            mt={2}
            onSubmitCapture={(e) => {
              submitFunction(e);
            }}
            display="normal"
          >
            <Stack spacing={4}>{children}</Stack>
            <Text color="red">{optionalEntries ? "* If Applicable" : ""}</Text>
            <Button
              fontFamily={"heading"}
              mt={8}
              w={"full"}
              type="submit"
              bgGradient="linear(to-r, project.theme100, project.theme)"
              color={"white"}
              _hover={{
                bgGradient: "linear(to-r, project.theme, project.theme50)",
                boxShadow: "xl",
              }}
              onClick={(e) => {
                submitFunction(e);
              }}
            >
              {submitText}
            </Button>

            {info ? (
              <Box margin="10px 0" textAlign="center">
                <Link
                  href={link.link}
                  textDecoration="none"
                  isExternal
                  color="white"
                >
                  <LinkLogo />
                </Link>
                {/* <Text fontWeight="bold" padding="5px 0">
                  EXHIBITING THIS YEAR AT THE ANGEL COURT:
                </Text>
                <ImageGrid /> */}
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Stack>
      </Center>
    );
  }
}
