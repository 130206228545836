import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getEndpoint } from "../api";
import { Center, Heading } from "@chakra-ui/react";

export default function RedirectShortenedUrl() {
  let { name } = useParams();
  let navigate = useNavigate();
  const [text, setText] = useState("");

  useEffect(() => {
    const getOriginalUrl = async () => {
      const endpoint = getEndpoint(`s/${name}`);
      try {
        const res = await fetch(endpoint);
        let originalUrl = await res.json();

        setText(originalUrl);

        if (originalUrl) {
          // Ensure the URL includes the protocol
          if (
            !originalUrl.startsWith("http://") &&
            !originalUrl.startsWith("https://")
          ) {
            originalUrl = "http://" + originalUrl;
          }
          window.location.href = originalUrl;
        } else {
          alert("URL not found");
          navigate("/");
        }
      } catch (error) {
        console.error("Error fetching original URL:", error);
        alert("Error fetching URL. Please try again");
        navigate("/");
      }
    };

    getOriginalUrl();
  }, [name, navigate]);

  return (
    <Center>
      <Heading>Redirecting {text ? { text } : " ..."}</Heading>
    </Center>
  );
}
