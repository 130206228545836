import { Box } from "@chakra-ui/react";
import ReactHlsPlayer from "react-hls-player";
import placeholderVideo from "../assets/placeholder.png";

export default function ForPastor() {
  return (
    <Box>
      <ReactHlsPlayer
        src={
          "https://cdnstack.internetmultimediaonline.org/auxano/cecanada1/playlist.m3u8"
        }
        autoPlay={true}
        controls={true}
        width="100%"
        height="auto"
        poster={placeholderVideo}
      />
    </Box>
  );
}
