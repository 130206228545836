import { Icon, Button } from "@chakra-ui/react";

export default function LinkLogo({ link, logo }) {
  return (
    <Button
      leftIcon={<KCIcon />}
      colorScheme="messenger"
      variant="solid"
      w={"full"}
    >
      Follow us on KingsChat
    </Button>
  );
}

const KCIcon = (props) => (
  <Icon viewBox="0 0 41.39 41.39" {...props} color="white">
    {/* <rect width="41.39" height="41.39" rx="2" fill="#2f92e5" /> */}
    <path
      fill="currentColor"
      d="M18.66 11.76c-7 0-12.68 4.07-12.68 9.1C6 23.75 7.86 26.33 10.8 28a7.33 7.33 0 0 1-2.12 3c-.66.64-.42 1.49 1 1.17 1-.22 4.27-1.14 5.77-2.46a17.77 17.77 0 0 0 3.22.29c7 0 12.68-4.07 12.68-9.09s-5.69-9.15-12.69-9.15Z"
    />
    <path
      fill="currentColor"
      d="M33.35 32.48c2.94-1.67 4.83-4.25 4.83-7.14s-1.91-5.51-4.89-7.18a8.76 8.76 0 0 1 .42 2.7A10.26 10.26 0 0 1 29 29.17a17.92 17.92 0 0 1-10.36 3.11c-.49 0-1 0-1.45-.06a15.81 15.81 0 0 0 8.29 2.21 17.58 17.58 0 0 0 3.21-.29c1.5 1.32 4.8 2.24 5.78 2.46 1.41.31 1.65-.53 1-1.17a7.32 7.32 0 0 1-2.12-2.95ZM9 13.49A15.49 15.49 0 0 1 14.89 11l.77-4.43a.55.55 0 0 0 .18 0 .6.6 0 0 0 .61-.57.61.61 0 0 0-1.21 0 .57.57 0 0 0 .13.36L13.09 8l-1.56-2.16a.61.61 0 1 0-.52 0L9.83 9 6.88 8a.88.88 0 0 0 0-.16.61.61 0 0 0-1.22 0 .6.6 0 0 0 .61.59.44.44 0 0 0 .16 0l.73 2.43-2.78 1a.61.61 0 0 0-.58-.42.6.6 0 1 0 .54.86l3.87 1.87c.26-.29.52-.49.79-.68Z"
    />
    <path
      fill="#2f92e5"
      d="M26.3 25.5a13.21 13.21 0 0 1-7.64 2.24 15.27 15.27 0 0 1-2.8-.26l-1.09-.21-.84.74a4.56 4.56 0 0 1-1 .61l.63-1.62-1.63-.92c-2.35-1.33-3.7-3.24-3.7-5.22A5.85 5.85 0 0 1 11 16.22 13.13 13.13 0 0 1 18.66 14a13.13 13.13 0 0 1 7.64 2.24 5.85 5.85 0 0 1 2.79 4.64 5.87 5.87 0 0 1-2.79 4.62Z"
    />
    <path
      fill="currentColor"
      d="M22.59 20.71a1 1 0 1 1-1-1 1 1 0 0 1 1 1ZM19.49 20.71a1 1 0 1 1-1-1 1 1 0 0 1 1 1ZM16.38 20.71a1 1 0 1 1-1-1 1 1 0 0 1 1 1Z"
    />
  </Icon>
);
