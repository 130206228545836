import {
  Box,
  Stack,
  Heading,
  Text,
  Container,
  Input,
  Button,
  SimpleGrid,
  useBreakpointValue,
  IconProps,
  Icon,
  Image,
} from "@chakra-ui/react";
import { useState } from "react";
import { getEndpoint, getOptions } from "../api";
import skate from "../assets/fellowships/york.jpg";
import InputCheckFormat from "./InputCheck";
import InputCheckboxFormat from "./InputCheckbox";
import { useNavigate } from "react-router-dom";
import DropFiles from "./DropFiles";
import InputFormat from "./InputFormat";

export default function SoulWon() {
  const [soulName, setSoulName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [campus, setCampus] = useState("");
  const [fellowship, setFellowship] = useState("");
  const [winName, setWinName] = useState("");
  const [relation, setRelation] = useState("");
  const [gaveROR, setGaveROR] = useState(false);
  const [praySalv, setPraySalv] = useState(false);
  const [massData, setMassData] = useState([]);
  const [dateWon, setDateWon] = useState("");
  let history = useNavigate();

  const getToday = () => {
    var local = new Date();
    local.setMinutes(local.getMinutes() - local.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
  };

  const newUser = async (e) => {
    e.preventDefault();
    let packet = {
      number: number,
      campus: campus,
      soulName: soulName,
      fellowship: fellowship,
      email: email,
      relation: relation,
      gaveROR: gaveROR,
      winName: winName,
      praySalv: praySalv,
      dateWon: dateWon !== "" ? dateWon : getToday(),
    };

    const options = getOptions(packet);
    const endpoint = getEndpoint("50dayWon");

    try {
      const res = await fetch(endpoint, options);
      const data = await res.json();

      console.log(data);
      return history.push("/50-days");
    } catch (error) {
      console.log(error);
      alert("Registration failed, please try again");
    }
  };

  const massDataUpload = async (e) => {
    e.preventDefault();

    Array.from(massData).forEach(async (file) => {
      let packet = {
        number: file.number,
        campus: file.campus,
        soulName: file.soulName,
        fellowship: file.fellowship,
        email: file.email,
        relation: file.relation,
        gaveROR: file.gaveROR,
        winName: file.winName,
        praySalv: file.praySalv,
        dateWon: file.date !== "" ? file.date : getToday(),
      };

      console.log(packet);

      const options = getOptions(packet);
      const endpoint = getEndpoint("50dayWon");

      try {
        const res = await fetch(endpoint, options);
        const data = await res.json();

        console.log(data);
        return history("/50-days");
      } catch (error) {
        console.log(error);
        alert("Registration failed, please try again");
      }
    });
  };

  return (
    <Box position={"relative"}>
      <Container
        as={SimpleGrid}
        maxW={"7xl"}
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 5, lg: 24 }}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Stack spacing={{ base: 10, md: 20 }}>
          <Image
            textAlign={useBreakpointValue({ base: "center", md: "left" })}
            src={skate}
            borderRadius="md"
          />
        </Stack>
        <Stack
          bg={"gray.50"}
          rounded={"xl"}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: "lg" }}
        >
          <Stack spacing={4}>
            <Heading
              color={"gray.800"}
              lineHeight={1.1}
              fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
            >
              100 Days Outreach Report
            </Heading>
            <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
              Register your souls won over the course of the program
            </Text>
          </Stack>
          <Box
            as={"form"}
            mt={10}
            onSubmitCapture={(e) => {
              newUser(e);
            }}
          >
            <Stack spacing={4}>
              <DropFiles returnFunction={setMassData} />
              <Button
                fontFamily={"heading"}
                mt={8}
                w={"full"}
                bgGradient="linear(to-r, project.theme100, project.theme)"
                color={"white"}
                _hover={{
                  bgGradient: "linear(to-r, project.theme, project.theme50)",
                  boxShadow: "xl",
                }}
                onClick={(e) => {
                  massDataUpload(e);
                }}
              >
                Register Mass
              </Button>
              <Text fontWeight={"bold"}>
                Evangelical Information: Fill as much info as you have
              </Text>
              <Input
                placeholder="Name of Soul e.g Mark Hill"
                type="text"
                bg={"gray.100"}
                border={0}
                color={"black"}
                value={soulName}
                onChange={(e) => {
                  setSoulName(e.target.value);
                }}
                _placeholder={{
                  color: "gray.500",
                }}
              />
              <InputCheckFormat
                inputName="Did they give their life to Christ?"
                inputValue={praySalv}
                returnFunction={setPraySalv}
              />
              <InputCheckFormat
                inputName="Did they recieve a copy of the Rhapsody?"
                inputValue={gaveROR}
                returnFunction={setGaveROR}
              />
              <Input
                placeholder="Their Email e.g. example@gmail.com"
                type="email"
                bg={"gray.100"}
                border={0}
                color={"black"}
                _placeholder={{
                  color: "gray.500",
                }}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <Input
                placeholder="Their School e.g. York University"
                type="text"
                bg={"gray.100"}
                border={0}
                color={"black"}
                _placeholder={{
                  color: "gray.500",
                }}
                value={campus}
                onChange={(e) => {
                  setCampus(e.target.value);
                }}
              />
              <Input
                placeholder="Their Number e.g. +16474787499"
                type="number"
                bg={"gray.100"}
                border={0}
                color={"black"}
                _placeholder={{
                  color: "gray.500",
                }}
                value={number}
                onChange={(e) => {
                  setNumber(e.target.value);
                }}
              />
              <InputFormat
                inputName={"Day Won"}
                inputType={"date"}
                inputValue={dateWon}
                returnFunction={setDateWon}
              />
              <InputCheckboxFormat
                inputName="Relation to you"
                inputValue={relation}
                returnFunction={setRelation}
              >
                <option value="">Select Option</option>
                <option value="Fellow Student">Fellow Student</option>
                <option value="Classmate">Classmate</option>
                <option value="Co-Worker">Co-Worker</option>
                <option value="Met in transit">Met in transit</option>
                <option value="Campus Environs">Campus Environs</option>
                <option value="Other">Other</option>
              </InputCheckboxFormat>
              <Text fontWeight={"bold"}>Your Personal Information</Text>
              <Input
                placeholder="Your Name e.g Jane Doe"
                type="text"
                bg={"gray.100"}
                border={0}
                color={"black"}
                value={winName}
                onChange={(e) => {
                  setWinName(e.target.value);
                }}
                _placeholder={{
                  color: "gray.500",
                }}
              />
              <InputCheckboxFormat
                inputName="Your Fellowship"
                inputValue={fellowship}
                returnFunction={setFellowship}
              >
                <option value="">Select Option</option>
                <option value="University of Alberta">
                  University of Alberta
                </option>
                <option value="Brock University">Brock University</option>
                <option value="University of Calgary">
                  University of Calgary
                </option>
                <option value="Carleton University">Carleton University</option>
                <option value="La Cite Collegiale">La Cite Collegiale</option>
                <option value="Durham College">Durham College</option>
                <option value="BLW Kitchener">BLW Kitchener</option>
                <option value="University of Lethbridge">
                  University of Lethbridge
                </option>
                <option value="Memorial University of Newfoundland">
                  Memorial University of Newfoundland
                </option>
                <option value="Université de Moncton">
                  Université de Moncton
                </option>
                <option value="North Island College (NIC)">
                  North Island College (NIC)
                </option>
                <option value="University of Ottawa">
                  University of Ottawa
                </option>
                <option value="BLW Quebec Fellowships">
                  BLW Quebec Fellowships
                </option>
                <option value="Ryerson University">Ryerson University</option>
                <option value="University of Regina">
                  University of Regina
                </option>
                <option value="University of Toronto - Scarborough">
                  University of Toronto - Scarborough
                </option>
                <option value="University of Toronto - Mississauga">
                  University of Toronto - Mississauga
                </option>
                <option value="BLW Vancouver">BLW Vancouver</option>
                <option value="BLW Winnipeg">BLW Winnipeg</option>
                <option value="York University">York University</option>
              </InputCheckboxFormat>
            </Stack>
            <Button
              fontFamily={"heading"}
              mt={8}
              w={"full"}
              bgGradient="linear(to-r, project.theme100, project.theme)"
              color={"white"}
              _hover={{
                bgGradient: "linear(to-r, project.theme, project.theme50)",
                boxShadow: "xl",
              }}
              onClick={(e) => {
                newUser(e);
              }}
            >
              Register
            </Button>
          </Box>
        </Stack>
      </Container>
      <Blur
        position={"absolute"}
        top={-10}
        left={-10}
        style={{ filter: "blur(70px)" }}
        zIndex="-10"
      />
    </Box>
  );
}

export const Blur = (props: IconProps) => {
  return (
    <Icon
      width={useBreakpointValue({ base: "100%", md: "40vw", lg: "30vw" })}
      zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
      height="560px"
      viewBox="0 0 528 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="71" cy="61" r="111" fill="#F56565" />
      <circle cx="244" cy="106" r="139" fill="#ED64A6" />
      <circle cy="291" r="139" fill="#ED64A6" />
      <circle cx="80.5" cy="189.5" r="101.5" fill="#ED8936" />
      <circle cx="196.5" cy="317.5" r="101.5" fill="#ECC94B" />
      <circle cx="70.5" cy="458.5" r="101.5" fill="#48BB78" />
      <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1" />
    </Icon>
  );
};
