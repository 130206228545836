import React, { createContext, useEffect, useState } from "react";
import { node } from "prop-types";

const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    if (sessionStorage.getItem("pack")) {
      let info = JSON.parse(sessionStorage.getItem("pack"));
      setUser(info);
    }
  }, []);

  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  );
};

const useUser = () => React.useContext(UserContext);

UserContextProvider.propTypes = {
  children: node.isRequired,
};

export default UserContextProvider;
export { useUser, UserContext };
