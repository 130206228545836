import { Stack, Heading, Text, Button, Box } from "@chakra-ui/react";

export default function JoinUs() {
  return (
    <Stack
      textAlign={"center"}
      align={"center"}
      spacing={{ base: 8, md: 10 }}
      py={{ base: 20, md: 28 }}
    >
      <Heading
        fontWeight={600}
        fontSize={{ base: "1xl", sm: "2xl", md: "4xl" }}
        lineHeight={"110%"}
      >
        Join Us{" "}
        <Text as={"span"} color={"project.theme"}>
          Today
        </Text>
      </Heading>
      <Text color={"gray.500"} maxW={"3xl"}>
        Young people hold an immense value for us, thus they require the right
        environment where their energies can be rightly harnessed and positively
        channeled; an environment where they would develop the right mental
        attitude and be molded for leadership. Their contact with the vision of
        our Ministry through the Campus Ministry is that answer that positively
        changes their lives forever.
      </Text>
      <Stack spacing={2} direction={"row"}>
        <Button
          rounded={"full"}
          px={3}
          colorScheme={"red"}
          bg={"project.theme"}
          _hover={{ bg: "project.theme100" }}
          as="a"
          href="/contact/contact-us"
        >
          Join a fellowship
        </Button>
      </Stack>
    </Stack>
  );
}
