import React, { useState } from "react";
import Form from "./Form";
import InputFormat from "./InputFormat";
import InputCheckboxFormat from "./InputCheckbox";
import countries from "../data/countries.json"; // Assume you have a JSON file of countries
import { getEndpoint, getOptions } from "../api";
import image from "../assets/spanish.jpg";
import video from "../assets/spanish.mp4";
import { Box, Button, Heading, Image, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import ReactHlsPlayer from "react-hls-player";

export default function HSLHSRegister() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [region, setRegion] = useState("");
  const [country, setCountry] = useState("");
  const [name, setName] = useState(
    `Healing Streams Servicios de sanación en vivo`
  );
  const [text, setText] = useState("");
  const [force, setForce] = useState(false);

  const registerExhibition = async (e) => {
    e.preventDefault();
    if (firstName && lastName && email && phone && region && country) {
      let packet = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        region: region,
        country: country,
      };

      const options = getOptions(packet);
      const endpoint = getEndpoint("hslhs_register");

      try {
        const res = await fetch(endpoint, options);
        const data = await res.json();

        if (data === true) {
          setName("¡GRACIAS POR REGISTRARSE!");
          setText(
            "Por favor, este atento a toda la comunicación respecto al programa."
          );
          setForce(!force);
        } else if (data === "Person already registered") {
          setName("Ya registrado");
          setText(
            "Este correo electrónico ya ha sido utilizado para registrarse. Asegúrese de no estar duplicando."
          );
          setForce(!force);
        }
      } catch (error) {
        console.error(error);
        alert("La registración falló, por favor intente de nuevo.");
      }
    } else {
      let missingFields = [];

      if (!firstName) missingFields.push("Nombre");
      if (!lastName) missingFields.push("Apellido");
      if (!email) missingFields.push("Correo Electrónico");
      if (!phone) missingFields.push("Número de Teléfono");
      if (!region) missingFields.push("Estado/Provincia/Región");
      if (!country) missingFields.push("País");

      alert(
        `Por favor, complete los siguientes campos: ${missingFields.join(", ")}`
      );
    }
  };

  let link = {
    link: "https://kingschat.online/user/blwcan",
    text: "Follow us on KingsChat to stay up to date",
  };

  return (
    <>
      {/* <header>
        <Image src={image} margin={"auto"} maxW="50%" p="2%" w="500px" />
      </header> */}

      <Box margin={"auto"} maxW="50%" p="2%" w="500px">
        <Heading
          color={"gray.800"}
          lineHeight={1.1}
          textAlign="center"
          fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
        >
          Mira en vivo aquí:
        </Heading>
        <ReactHlsPlayer
          src={
            "https://tni-out.cdn.cscloudws.com/translations/spanish/playlist.m3u8"
          }
          autoPlay={true}
          controls={true}
          width="100%"
          height="auto"
        />
      </Box>

      <Box
        textAlign={"center"}
        backgroundColor={"black"}
        color={"white"}
        py="2%"
      >
        <Text px="5%" fontWeight={"bold"} pb="1%">
          Acelera tu fe para la próxima edición de los Servicios de Sanación en
          Vivo de Healing Streams, un evento monumental destinado a reunir a
          miles de millones de personas en todo el mundo para una experiencia
          sobrenatural del amor de Dios, culminando en la sanación de los
          enfermos y milagros extraordinarios. Marca tus calendarios para los
          días tan esperados desde el viernes 27 hasta el domingo 29 de octubre
          de 2023.
        </Text>
        <Button
          as={Link}
          fontSize={"xs"}
          fontWeight={600}
          color={"white"}
          bg={"project.theme"}
          onClick={() => {
            const endpoint = getEndpoint("online/hslhs");
            fetch(endpoint).then((res) => res.json());
          }}
          _hover={{
            bg: "project.theme50",
          }}
          m="auto"
          to={"/httn.pdf"}
          target="_blank"
          download="httn.pdf"
        >
          Descargar la revista HTTN
        </Button>
      </Box>

      <Form
        name={name}
        text={text}
        optionalEntries={false}
        submitFunction={registerExhibition}
        submitText="Registrar"
        info={true}
        link={link}
        force={force}
      >
        <InputFormat
          inputName="Nombre"
          ifApplicable={false}
          inputType="text"
          inputPlaceholder="Juan"
          inputValue={firstName}
          returnFunction={setFirstName}
        />

        <InputFormat
          inputName="Apellido"
          ifApplicable={false}
          inputType="text"
          inputPlaceholder="Doe"
          inputValue={lastName}
          returnFunction={setLastName}
        />

        <InputFormat
          inputName="Correo Electrónico"
          ifApplicable={false}
          inputType="email"
          inputPlaceholder="ejemplo@dominio.com"
          inputValue={email}
          returnFunction={setEmail}
        />

        <InputFormat
          inputName="Número de Teléfono"
          ifApplicable={false}
          inputType="text"
          inputPlaceholder="123-456-7890"
          inputValue={phone}
          returnFunction={setPhone}
        />

        <InputFormat
          inputName="Estado/Provincia/Región"
          ifApplicable={false}
          inputType="text"
          inputPlaceholder="Estado/Provincia/Región"
          inputValue={region}
          returnFunction={setRegion}
        />

        <InputCheckboxFormat
          inputName="País"
          ifApplicable={false}
          inputValue={country}
          returnFunction={setCountry}
        >
          <option value="">Seleccionar País</option>
          {countries.map((country) => (
            <option value={country.code} key={country.code}>
              {country.name}
            </option>
          ))}
        </InputCheckboxFormat>
      </Form>
    </>
  );
}
