import { Box, Heading, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function SpecialService() {
  const navigate = useNavigate();

  useEffect(() => {
    document.addEventListener("contextmenu", (event) => event.preventDefault());

    const disableShortcuts = (e) => {
      if (e.ctrlKey && (e.key === 's' || e.key === 'S' || e.key === 'p' || e.key === 'P')) {
        e.preventDefault();
      }
    };
    window.addEventListener('keydown', disableShortcuts);

    return () => {
      window.removeEventListener('keydown', disableShortcuts);
    };
  }, []);

  const checkLogin = () => {
    const pack = sessionStorage.getItem("pack");
    if (pack) {
      return true;
    } else {
      alert("You must be logged in to watch live");
      sessionStorage.setItem("comingFrom", "/special");
      return navigate("/login");
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  const sessions = [
    {
      sessionTitle: "Session 1",
      videos: [
        {
          src: "https://lwcanada.org/videos/My_Expectations_Panel_Discussion.mp4",
          title: "PANEL DISCUSSION: EXPECTATIONS – SOULWINNERS & CELL LEADERS"
        },
        {
          src: "https://lwcanada.org/videos/ICLC2024_PST_LANRE_EFFECTIVE_AND_STRATEGIC_USE_IN_ACHIEVING_EVERY_CELL_A_THOUSAND_COMP.mp4",
          title: "SUPER SESSION: EFFECTIVE AND STRATEGIC USE OF OUR MINISTRY OUTREACHES AND CAMPAIGNS IN DRIVING & ACHIEVING “EVERY CELL A THOUSAND”"
        },
        {
          src: "https://lwcanada.org/videos/Masterclass_1_Part_1_Pastor_Emeka_Eze.mp4",
          title: "MASTER CLASS: “EVERY CELL A THOUSAND”; THE WHY & HOW- PRACTICAL STEPS/STRATEGIES FOR ACCOMPLISHING THE MANDATE IN THE NEXT 5 MONTHS. (PART ONE)"
        },
        {
          src: "https://lwcanada.org/videos/Quiz.mp4",
          title: "QUIZ SHOW: CELL SYSTEM MANUAL"
        }
      ]
    },
    {
      sessionTitle: "Session 2",
      videos: [
        {
          src: "https://lwcanada.org/videos/ICLC_2024_Day_1_Rev_Chris_comp.mp4",
          title: "PLENARY SESSION: The President"
        },
        {
          src: "https://lwcanada.org/videos/Pastor_Joy_Amenkhienan_Five_Month_Growth_Plan_Super_Session%20Mp4.mp4",
          title: "Friday Evening Session: Five Month Growth Plan"
        }
      ]
    },
    {
      sessionTitle: "Session 3",
      videos: [
        {
          src: "https://lwcanada.org/videos/Pastor_Yemi_Akinwunmi_LTM_And_Radio_Strategy_Session.mp4",
          title: "Saturday Evening Session: LTM/Radio Strategy Session"
        },
        {
          src: "https://lwcanada.org/videos/Loveworld_Networks_Strategy_Session_Pastor_Ose_Oyakhilome.mp4",
          title: "Loveworld Networks Strategy Session"
        },
        {
          src: "https://lwcanada.org/videos/ICLC_2024_PASTOR_LINDA_REVERENCE_AND_ORDER_IN_THE_HOUSE_OF_GOD_COMP.mp4",
          title: "Super Session: Reverence and Order in the House of God"
        },
        {
          src: "https://lwcanada.org/videos/PASTOR_VALE%20_GATHER_IN_THE_FRAGMENT_THAT_NOTHING_BE_WASTED_compressed.mp4",
          title: "Super Session: Gather In The Fragments That Nothing Be Wasted"
        }
      ]
    },
    {
      sessionTitle: "Session 4",
      videos: [
        {
          src: "https://lwcanada.org/videos/ICLC_2024_PASTOR_AMBROSE_THE_HOUR_OF_PRAYER_COMP.mp4",
          title: "Sunday Evening Session: The Hour of Prayer - 60 Minutes to Change"
        },
        {
          src: "https://lwcanada.org/videos/Masterclass_1_Part_2_Pastor_Emeka_Eze.mp4",
          title: "Master Class: Every Cell A Thousand (Part 2)"
        },
        {
          src: "https://lwcanada.org/videos/REV_TOM_STRATEGIES_FOR_GLOBAL_CHRISTIANISATION_compressed.mp4",
          title: "Super Session: Strategies For Global Christianisation"
        },
        {
          src: "https://lwcanada.org/videos/Inner_City_Missions_Strategy_Session.mp4",
          title: "Innercity Missions Strategy Session"
        }
      ]
    },
    {
      sessionTitle: "Session 5",
      videos: [
        {
          src: "https://lwcanada.org/videos/The_Protagonist_Cell_Leader_by_Pastor_Yemisi_Kudehibu.mp4",
          title: "Monday Evening Session: Cells Everywhere"
        },
        {
          src: "https://lwcanada.org/videos/Rhapsody_of_Realities_Strategy_Session.mp4",
          title: "Rhapsody of Realities Strategy Session"
        },
        {
          src: "https://lwcanada.org/videos/Foundation_School_and_CGI_Strategy_Session.mp4",
          title: "CGI & Foundation School Strategy Session"
        },
        {
          src: "https://lwcanada.org/videos/PASTOR_GABRIEL_THE_ELEVENTH_HOUR_HARVESTERS_MOBILIZING_FOR_THE_FINAL_HARVEST_COMP.mp4",
          title: "Super Session: The 11th Hour Harvesters"
        },
        {
          src: "https://lwcanada.org/videos/Loveworld_Publishing_Ministry_Strategy_Session.mp4",
          title: "Loveworld Publishing Ministry Strategy Session"
        },
        {
          src: "https://lwcanada.org/videos/New_Media_Technologies_Strategy_Session.mp4",
          title: "New Media Technologies Strategy Session"
        }
      ]
    },
    {
      sessionTitle: "Session 6",
      videos: [
        {
          src: "https://lwcanada.org/videos/Panel_Discussion_for_Leaders_Who_Have_Exceeded_the_1000_Mandate_Pastor_Emeka_Eze.mp4",
          title: "Tuesday Evening Session: Leaders Who Have Exceeded The 1000 Mandate"
        },
        {
          src: "https://lwcanada.org/videos/ICLC_2024_Day_2_Part_1_Rev_Chris.mp4",
          title: "Plenary Session with the President Day 2 (Part 1)"
        },
        {
          src: "https://lwcanada.org/videos/ICLC_2024_Day_2_Part_2_Rev_Chris.mp4",
          title: "Plenary Session with the President Day 2 (Part 2)"
        }
      ]
    }
  ];

  return (
    <Box onContextMenu={(e) => e.preventDefault()}>
      {/* <Heading>ICLC In Your City Videos</Heading>
      <Tabs isFitted variant="enclosed">
        <TabList>
          {sessions.map((session, index) => (
            <Tab key={index}>{session.sessionTitle}</Tab>
          ))}
        </TabList>

        <TabPanels>
          {sessions.map((session, index) => (
            <TabPanel key={index}>
              <Tabs isFitted variant="enclosed">
                <TabList>
                  {session.videos.map((video, vidIndex) => (
                    <Tab key={vidIndex}>{video.title}</Tab>
                  ))}
                </TabList>

                <TabPanels>
                  {session.videos.map((video, vidIndex) => (
                    <TabPanel key={vidIndex}>
                      <video
                        src={video.src}
                        controls={true}
                        width="100%"
                        height="auto"
                        controlsList="nodownload"
                      />
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs> */}
    </Box>
  );
}
