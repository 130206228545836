import React from "react";
import {
  Text,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Heading,
} from "@chakra-ui/react";
import Location from "./Location";

export default function FellowshipList() {
  let tabs = [
    {
      location: "Ontario",
      code: "ON",
    },
    {
      location: "Alberta",
      code: "AB",
    },
    {
      location: "Newfoundland and Labrador",
      code: "NL",
    },
    {
      location: "Manitoba",
      code: "MB",
    },
    {
      location: "Quebec",
      code: "QC",
    },
    {
      location: "British Columbia",
      code: "BC",
    },
    {
      location: "New Brunswick",
      code: "NB",
    },
    {
      location: "Prince Edward Island",
      code: "PE",
    },
    {
      location: "Nova Scotia",
      code: "NS",
    },
    {
      location: "Saskatchewan",
      code: "SK",
    },
    {
      location: "Northwest Territories",
      code: "NT",
    },
    {
      location: "Yukon",
      code: "YT",
    },
    {
      location: "Nunavut",
      code: "NU",
    },
  ];

  return (
    <Box>
      <Accordion allowToggle>
        {tabs.map((tab) => (
          <AccordionItem bg={"white"}>
            <h2>
              <AccordionButton>
                <Heading
                  textAlign="center"
                  color={"project.theme"}
                  textTransform={"uppercase"}
                  fontWeight={800}
                  fontSize="1rem"
                  letterSpacing={1.1}
                >
                  {tab.location}
                </Heading>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Location code={tab.code} />
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
}
