import { Text, Image, Box, Flex, Stack, SimpleGrid } from "@chakra-ui/react";
import one from "../assets/top-soul.png";

export default function RankList() {
  return (
    <Box textAlign={"center"} padding={"1%"}>
      <Image textAlign={"center"} src={one} borderRadius="md" />
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 5, lg: 24 }}
        py={{ base: 5, sm: 10, lg: 16 }}
      >
        <Box>
          <Text
            fontWeight="black"
            fontSize="14px"
            textAlign={"center"}
            background={"#2E92E5"}
            margin="auto"
            color={"white"}
            textTransform={"uppercase"}
            letterSpacing={"wider"}
            marginBottom={"1%"}
            borderRadius={"md"}
            padding={"5px"}
          >
            Top Soul Winners - Overall
          </Text>
          <Stack>
            <Text>1. Brother Treasure Bright</Text>
            <Text>2. Sister Tosin Ajibulu</Text>
            <Text>3. Brother Jason Chan</Text>
            <Text>4. Sister Beomi Labio</Text>
            <Text>5. Sister Natanya Dara</Text>
            <Text>6. Sister Doreen Owase</Text>
            <Text>7. Sister Chloe Isesele</Text>
            <Text>8. Sister Karen Ajai</Text>
            <Text>9. Sis Juanita and Sis Thelma</Text>
            <Text>10. Sister Axelle Wilson</Text>
          </Stack>
        </Box>
        <Box>
          <Text
            fontWeight="black"
            fontSize="14px"
            textAlign={"center"}
            background={"#2E92E5"}
            margin="auto"
            color={"white"}
            textTransform={"uppercase"}
            letterSpacing={"wider"}
            marginBottom={"1%"}
            borderRadius={"md"}
            padding={"5px"}
          >
            Top Soul Winners - Week of Feb 7
          </Text>
          <Stack>
            <Text>1. Brother Treasure Bright</Text>
            <Text>2. Sister Doreen Owase</Text>
            <Text>3. Sister Chloe Isesele</Text>
            <Text>4. Brother Jason Chan</Text>
            <Text>5. Brother Bryan Oladeji</Text>
            <Text>6. Sister Natanya Dara</Text>
            <Text>7. Sister Natasha Dara</Text>
            <Text>8. Sister Kimberly Kumi</Text>
            <Text>9. Sister Axelle Wilson</Text>
            <Text>10. Sis Juanita and Sis Thelma</Text>
          </Stack>
        </Box>
      </SimpleGrid>
    </Box>
  );
}

const ImageBox = ({ image, size }) => (
  <Box w={size ? size : "40px"} margin="auto">
    <Image src={image} />
  </Box>
);
const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};
