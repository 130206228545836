import { Box, Text, Checkbox } from "@chakra-ui/react";

export default function InputCheckFormat({
  inputName,
  ifApplicable,
  inputValue,
  returnFunction,
}) {
  return (
    <Box>
      <Text>
        {inputName}
        <Text as="span" color="red">
          {" "}
          {ifApplicable ? "*" : ""}
        </Text>
      </Text>
      <Checkbox
        m="4px"
        p="4px"
        borderRadius={"md"}
        value={true}
        onChange={(e) => {
          returnFunction(e.target.value);
        }}
        bg={"gray.100"}
        border={"1px solid #D9926E"}
        color={"black"}
      >
        Yes
      </Checkbox>
      <Checkbox
        m="4px"
        p="4px"
        borderRadius={"md"}
        value={false}
        onChange={(e) => {
          returnFunction(e.target.value);
        }}
        bg={"gray.100"}
        border={"1px solid #D9926E"}
        color={"black"}
      >
        No
      </Checkbox>
    </Box>
  );
}
