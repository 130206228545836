import { Input, Box, Text } from "@chakra-ui/react";

export default function InputFormat({
  inputName,
  ifApplicable,
  inputType,
  inputPlaceholder,
  inputValue,
  returnFunction,
}) {
  return (
    <Box>
      <Text>
        {inputName}
        <Text as="span" color="red">
          {" "}
          {ifApplicable ? "*" : ""}
        </Text>
      </Text>
      <Input
        placeholder={inputPlaceholder}
        type={inputType}
        bg={"gray.100"}
        border={0}
        color={"black"}
        _placeholder={{
          color: "gray.500",
        }}
        value={inputValue}
        onChange={(e) => {
          returnFunction(e.target.value);
        }}
      />
    </Box>
  );
}
