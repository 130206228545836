import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import logo from "../assets/can-logo.png";
import { useUser } from "../context/UserContext";
import { useEffect, useState } from "react";

export default function WithSubnavigation() {
  const { user } = useUser();
  const { isOpen, onToggle } = useDisclosure();
  const linkColor = useColorModeValue("gray.600", "gray.200");

  //Check if user is logged in
  const checkLogin = () => {
    const pack = sessionStorage.getItem("pack");
    if (pack) {
      return true;
    } else {
      return false;
    }
  };
  const [loggedIn, setLoggedIn] = useState(checkLogin());

  let loginToggle = loggedIn ? (
    <Stack
      flex={{ base: 1, md: 0 }}
      justify={"flex-end"}
      direction={"row"}
      spacing={6}
    >
      <Text
        fontSize={"sm"}
        fontWeight={500}
        color={linkColor}
        isTruncated
        maxW={{ base: "100px", sm: "none" }}
        padding="3px"
        lineHeight={"33.3px"}
      >
        {user.name}
      </Text>
      <Button
        fontSize={"xs"}
        fontWeight={600}
        color={"white"}
        bg={"project.theme"}
        onClick={() => {
          sessionStorage.removeItem("pack");
          sessionStorage.removeItem("reloaded");
          window.location.reload();
        }}
        _hover={{
          bg: "project.theme50",
        }}
      >
        Sign Out
      </Button>
    </Stack>
  ) : (
    <Stack
      flex={{ base: 1, md: 0 }}
      justify={"flex-end"}
      direction={"row"}
      spacing={6}
    >
      <Button
        as={"a"}
        fontSize={"sm"}
        fontWeight={400}
        variant={"link"}
        href={"/login"}
      >
        Sign In
      </Button>
      <Button
        display={{ base: "none", md: "inline-flex" }}
        as={"a"}
        fontSize={"sm"}
        fontWeight={600}
        color={"white"}
        bg={"project.theme"}
        href={"/register"}
        _hover={{
          bg: "project.theme50",
        }}
      >
        Sign Up
      </Button>
    </Stack>
  );

  return (
    <Box>
      <Flex
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
      >
        <Flex
          flex={{ base: 1, lg: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", lg: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          <Link href="/">
            <Image
              textAlign={useBreakpointValue({ base: "center", md: "left" })}
              src={logo}
              maxW="100px"
            />
          </Link>

          <Flex
            display={{ base: "none", lg: "flex" }}
            ml={10}
            lineHeight="33.33px"
          >
            <DesktopNav />
          </Flex>
        </Flex>

        {loginToggle}
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ lg: "none" }}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const DesktopNav = () => {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link
                p={2}
                href={navItem.href ?? "#"}
                fontSize={"sm"}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
                target={navItem.outbound ? "_blank" : undefined}
                rel={navItem.outbound ? "noopener noreferrer" : undefined}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel, outbound }: NavItem) => {
  return (
    <Link
      href={href}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("project.light", "gray.900") }}
      target={outbound ? "_blank" : undefined}
      rel={outbound ? "noopener noreferrer" : undefined}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "white" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"project.theme"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNavItem = ({ label, children, href, outbound }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
        target={outbound ? "_blank" : undefined}
        rel={outbound ? "noopener noreferrer" : undefined}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href} target={child.outbound ? "_blank" : undefined} rel={child.outbound ? "noopener noreferrer" : undefined}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
  outbound?: boolean;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: "About",
    children: [
      {
        label: "Christ Embassy",
        subLabel: "Our purpose, mission and vision",
        href: "/about/christ-embassy",
      },
      {
        label: "Reverend Dr Chris Oyakhilome",
        subLabel: "President, Loveworld Inc",
        href: "/about/pastor-chris",
      },
      {
        label: "BLW Canada",
        subLabel: "Taking the gospel to the campus",
        href: "/about/blwcanada",
      },
    ],
  },
  {
    label: "Fellowships",
    href: "/fellowships",
  },
  {
    label: "Videos",
    children: [
      {
        label: "Watch Live",
        subLabel: "Tune in to watch our live programs",
        href: "/watch-live",
      },
    ],
  },
  {
    label: "Contact",
    children: [
      {
        label: "Prayer Request",
        subLabel:
          "Do you have something you would like us to pray for? Send us a message now",
        href: "/contact/prayer-request",
      },
      {
        label: "Prayer of Salvation",
        subLabel: "Accept Jesus Christ as your Lord and Savior today",
        href: "/contact/salvation",
      },
      {
        label: "Get in Touch",
        subLabel: "Send us a message, let's hear from you",
        href: "/contact/contact-us",
      },
    ],
  },
  {
    label: "Programs",
    children: [
      {
        label: "Coming to Canada",
        subLabel: "Orientation Program for New Students",
        href: "/ctc",
      },
      {
        label: "Prayer and Fasting",
        subLabel: "31 Days of Prayer and Fasting",
        href: "/prayer-and-fasting",
      },
      {
        label: "100DGTH",
        subLabel: "100 Days of Gathering the Harvest!",
        href: "/100-days",
      },
      {
        label: "ICLC 2023",
        subLabel:
          "Registration for the International Cell Leaders Conference 2022",
        href: "/iclc-registration",
      },
    ],
  },
  { label: "Online Church", href: "https://online.lwcanada.org", outbound: true },
  { label: "Rock Solid", href: "https://foundationsch.lwcanada.org", outbound: true },
];

