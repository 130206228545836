import React, { Suspense } from "react";
import { Box } from "@chakra-ui/react";
import WithSubnavigation from "./components/Nav";
import LargeWithNewsletter from "./components/Footer";
import UserContextProvider from "./context/UserContext";
import Paths from "./Routes";

export default function App() {
  return (
    <UserContextProvider>
      <Box>
        <WithSubnavigation />
        <Box minH="60vh">
          <Paths />
        </Box>
        <LargeWithNewsletter />
      </Box>
    </UserContextProvider>
  );
}
