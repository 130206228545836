import { Box, Text, Heading } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getEndpoint } from "../api";

export default function WatchPage({ match }) {
  let _id = match.params._id;

  const [data, setData] = useState({});
  let navigate = useNavigate();

  if (sessionStorage.getItem("pack")) {
    let pack = JSON.parse(sessionStorage.getItem("pack"));
  } else if (sessionStorage.getItem("pack") === null) {
    return navigate("/login");
  }

  if (sessionStorage.getItem("reloaded")) {
    let reloaded = sessionStorage.getItem("reloaded");
  } else {
    sessionStorage.setItem("reloaded", true);
    window.location.reload(false);
  }

  useEffect(() => {
    getVideoInfo();
  }, []);

  const getVideoInfo = () => {
    let string = "webtv/" + _id;
    const endpoint = getEndpoint(string);
    fetch(endpoint)
      .then((res) => res.json())
      .then((res) => {
        setData(res);
      });
  };

  return (
    <Box>
      <Box padding="1%">
        <Heading fontSize="lg">{data.name}</Heading>
        <Text fontWeight="300" fontSize="16px">
          {data.description}
        </Text>
      </Box>
      <video
        src={data.link}
        autoPlay={true}
        controls={true}
        width="100%"
        height="auto"
        poster={data.thumbnail}
      />
    </Box>
  );
}
