import { Box, Heading, Link, HStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getEndpoint } from "../api";
import Video from "../components/Video";
import { Link as ReactRouterLink } from "react-router-dom";

export default function OnDemand() {
  const [videos, setVideos] = useState([]);
  let navigate = useNavigate();

  //Check if user is logged in
  const checkLogin = () => {
    const pack = sessionStorage.getItem("pack");
    if (pack) {
      return true;
    } else {
      alert("You must be logged in to watch on demand");
      return navigate("/login");
    }
  };

  const getVideos = async () => {
    const endpoint = getEndpoint("webtv/videos");

    try {
      const res = await fetch(endpoint);
      const data = await res.json();
      setVideos(data);
    } catch (error) {
      console.log(error);
      alert("Login failed, please try again");
    }
  };

  useEffect(() => {
    checkLogin();
    getVideos();
  }, []);

  return (
    <Box textAlign="center" marginTop={5}>
      <Heading fontSize="lg">On Demand</Heading>

      <HStack spacing="8" justify>
        {videos.map((video) => {
          return (
            <Link
              as={ReactRouterLink}
              to={{
                pathname: "/videos/" + video._id,
                state: { video },
              }}
              width={{ base: "100%", sm: "45%", md: "45%", lg: "30%" }}
              maxW="300px"
            >
              <Video info={video} />
            </Link>
          );
        })}
      </HStack>
    </Box>
  );
}
