import { Box, Button, Image, Heading, Stack, Text } from "@chakra-ui/react";
import Carousel from "../components/Carousel";
import { Link } from "react-router-dom";
import rowcan from "../assets/rowcan.png";
import banner from "../assets/rocan/01.jpg";
import { getEndpoint } from "../api";

export default function LandingROC() {
  return (
    <>
      <Box margin={"auto"}>
        <Image src={rowcan} margin={"auto"} w="200px" />
        <Heading
          fontWeight={600}
          fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
          textAlign={"center"}
          pb={5}
        >
          ReachOut World
          <Text as={"span"} color={"project.theme"}>
            {" "}
            Canada{" "}
          </Text>
        </Heading>
      </Box>

      {/* <Carousel /> */}
      <Image src={banner} margin={"auto"} w={{ base: "100%", md: "80%" }} />
      <Stack>
        <Stack
          textAlign={"center"}
          align={"center"}
          spacing={{ base: 8, md: 10 }}
          py={10}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: "xl", sm: "2xl", md: "3xl" }}
            lineHeight={"110%"}
          >
            Empowering You with the
            <Text as={"span"} color={"project.theme"}>
              {" "}
              Gospel:{" "}
            </Text>
            Spreading Hope and Truth
          </Heading>
          <Button
            fontSize={"xs"}
            fontWeight={600}
            color={"white"}
            bg={"project.theme"}
            _hover={{
              bg: "project.theme50",
            }}
            as={Link}
            to={"/contact/contact-us"}
          >
            CONTACT US
          </Button>
        </Stack>
        <Box w={"80%"} m="auto" textAlign={"center"}>
          <Heading fontWeight={600} fontSize={"xl"} color={"project.theme"}>
            Digital Downloads
          </Heading>
          <Stack
            maxW={"6xl"}
            spacing={{ base: 8, md: 10 }}
            justify={"center"}
            py={2}
            direction={["column", "row"]}
          >
            <Box textAlign={"center"}>
              <Text>
                Rhapsody of Realities is a life guide that brings you a fresh
                perspective from God’s Word everyday. It features the day’s
                topic, a theme scripture, the day’s message, the daily
                confession and the Bible reading plan segment
              </Text>
              <Button
                as={Link}
                fontSize={"xs"}
                fontWeight={600}
                color={"white"}
                bg={"project.theme"}
                onClick={() => {
                  const endpoint = getEndpoint("online/rhapsody");
                  fetch(endpoint).then((res) => res.json());
                }}
                _hover={{
                  bg: "project.theme50",
                }}
                m="auto"
                to={"/rhapsody.pdf"}
                target="_blank"
                download="July Rhapsody.pdf"
              >
                Download ROR
              </Button>
            </Box>

            <Box textAlign={"center"}>
              <Text>
                “Now That You Are Born Again” is a powerful mini book that
                reveals, in a nutshell, what it means to be a Christian, and
                addresses practical issues faced by a new convert with answers
                to his/her most pertinent & troubling questions.
              </Text>
              <Button
                as={Link}
                fontSize={"xs"}
                fontWeight={600}
                color={"white"}
                bg={"project.theme"}
                onClick={() => {
                  const endpoint = getEndpoint("online/ntyba");
                  fetch(endpoint).then((res) => res.json());
                }}
                _hover={{
                  bg: "project.theme50",
                }}
                m="auto"
                to={"/ntyba.pdf"}
                target="_blank"
                download="NTYBA.pdf"
              >
                Download NTYBA
              </Button>
            </Box>
          </Stack>
          <Box textAlign={"center"} py={5}>
            <Heading fontWeight={600} fontSize={"xl"} color={"project.theme"}>
              Healing Streams
            </Heading>
            <Text>
              The Healing Streams is a global healing crusade that produces
              magnificent results, with opportunities to participate via virtual
              and physical healing centers with countless testimonies of
              healing, salvation and total transformation. Watch some
              testimonies below
            </Text>
          </Box>
          <Stack
            maxW={"6xl"}
            spacing={{ base: 8, md: 10 }}
            justify={"center"}
            py={2}
            direction={["column", "row"]}
          >
            <Box maxW="100%" w="400px">
              <video
                src={"https://ethsch.org/videos/VOTW_DEC11.mp4"}
                width="100%"
                height="auto"
                controls={true}
                onPlay={() => {
                  const endpoint = getEndpoint("online/hstreams");
                  fetch(endpoint).then((res) => res.json());
                }}
              />
            </Box>

            <Box maxW="100%" w="400px">
              <video
                src={"https://hsch.ceflixcdn.com/eths/chainvow.mp4"}
                controls={true}
                w="100%"
                height="auto"
                onPlay={() => {
                  const endpoint = getEndpoint("online/hstreams");
                  fetch(endpoint).then((res) => res.json());
                }}
              />
            </Box>
          </Stack>
        </Box>

        <Stack
          textAlign={"center"}
          align={"center"}
          spacing={{ base: 8, md: 10 }}
          py={10}
        >
          <Text maxW={{ base: "sm", md: "6xl" }}>
            The Loveworld Campus Ministry is designed to equip you for your walk
            with God, and to develop leadership potentials in you through the
            word, raising a generation of leaders that are ablaze for our Lord
            Jesus Christ. With fellowships all accross Canada, we invite you to
            come worship with us.
          </Text>
          <Button
            fontSize={"xs"}
            fontWeight={600}
            color={"white"}
            bg={"project.theme"}
            onClick={() => {
              console.log("Sign Out");
            }}
            _hover={{
              bg: "project.theme50",
            }}
            as={Link}
            to={"/contact/contact-us"}
          >
            Join a fellowship
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
