import Form from "./Form";
import { useState } from "react";
import InputFormat from "./InputFormat";
import { getEndpoint, getOptions } from "../api";
import { Stack, Text } from "@chakra-ui/react";
import InputCheckboxFormat from "./InputCheckbox";
import data from "../data/zones.json";
import OptionField from "./Option";

export default function IclcRegistration() {
  const [title, setTtile] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fellowship, setFellowship] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [force, setForce] = useState(false);
  const [name, setName] = useState(`ICLC 2023 REGISTRATION`);
  const [text, setText] = useState("");
  const [zones, setZones] = useState(data);
  const [role, setRole] = useState("");
  const [other, setOther] = useState("");
  const [checks, setChecks] = useState([]);

  const registerExhibition = async (e) => {
    e.preventDefault();
    if (
      title &&
      role &&
      firstName &&
      lastName &&
      email &&
      fellowship &&
      number
    ) {
      let packet = {
        title: title,
        role: role,
        firstName: firstName,
        lastName: lastName,
        fellowship: fellowship,
        email: email,
        number: number,
      };

      const options = getOptions(packet);
      const endpoint = getEndpoint("iclc_register");

      try {
        const res = await fetch(endpoint, options);
        const data = await res.json();

        if (data === true) {
          console.log("here");
          setName("THANK YOU FOR REGISTERING!");
          setText(
            "Please look out for all communication regarding the program"
          );
          setForce(!force);
        } else if (data === "Person already registered") {
          setName("Already Registered");
          setText(
            "This email has already been used to register. Please make sure you're not duplicating"
          );
          setForce(!force);
        }
      } catch (error) {
        console.log(error);
        alert("Registration failed, please try again");
      }
    } else {
      if (!title) {
        alert("Select your title first");
      } else if (!role) {
        alert("Select your role first");
      } else if (!firstName) {
        alert("Put in your first name first");
      } else if (!lastName) {
        alert("Put in your last name first");
      } else if (!email) {
        alert("Put in your email first");
      } else if (!fellowship) {
        alert("select your fellowship first");
      } else if (!number) {
        alert("Put in your number first");
      }
    }
  };

  let link = {
    link: "https://kingschat.online/user/blwcan",
    text: "Follow us on KingsChat to stay up to date",
  };

  return (
    <Form
      name={name}
      optionalEntries={false}
      submitFunction={registerExhibition}
      submitText="Register"
      text={text}
      force={force}
      link={link}
      info={true}
    >
      <InputCheckboxFormat
        inputName="Role"
        inputValue={role}
        returnFunction={setRole}
      >
        <option value="">Select Option</option>
        <option value="Coordinator">Coordinator</option>
        <option value="Cell Leader/Cell Executive/Bible Study Class Teacher">
          Cell Leader/Cell Executive/Bible Study Class Teacher
        </option>
        <option value="Leader in Training">Leader in Training</option>
      </InputCheckboxFormat>
      <InputCheckboxFormat
        inputName="Title"
        inputValue={title}
        returnFunction={setTtile}
      >
        <option value="">Select Option</option>
        <option value="Pastor">Pastor</option>
        <option value="Brother">Brother</option>
        <option value="Sister">Sister</option>
      </InputCheckboxFormat>
      <Stack direction={["column", "row"]}>
        <InputFormat
          inputName="First Name"
          ifApplicable={false}
          inputType="text"
          inputPlaceholder="John"
          inputValue={firstName}
          returnFunction={setFirstName}
        />
        <InputFormat
          inputName="Last Name"
          ifApplicable={false}
          inputType="text"
          inputPlaceholder="Doe"
          inputValue={lastName}
          returnFunction={setLastName}
        />
      </Stack>
      <InputCheckboxFormat
        inputName="Fellowship"
        inputValue={fellowship}
        returnFunction={setFellowship}
      >
        <option value="">Select Option</option>
        {zones.map((zone) => {
          return <OptionField option={zone.name} />;
        })}
      </InputCheckboxFormat>
      {fellowship === "Other" ? (
        <InputFormat
          inputName="Enter Other"
          ifApplicable={false}
          inputType="text"
          inputPlaceholder="Fellowship"
          inputValue={other}
          returnFunction={setOther}
        />
      ) : (
        ""
      )}
      <InputFormat
        inputName="Email Address"
        ifApplicable={false}
        inputType="email"
        inputPlaceholder="example@gmail.com"
        inputValue={email}
        returnFunction={setEmail}
      />
      <InputFormat
        inputName="Number (KingsChat Number)"
        ifApplicable={false}
        inputType="text"
        inputPlaceholder="+16783799533"
        inputValue={number}
        returnFunction={setNumber}
      />
    </Form>
  );
}
