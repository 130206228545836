import React, { useState } from "react";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  LoadScript,
  StandaloneSearchBox,
  MarkerClusterer,
} from "@react-google-maps/api";
import universitiesData from "./data/universities.json";
import logo from "../../assets/blw logo red.png";

const mapContainerStyle = {
  width: "100%",
  height: "500px",
};

const center = {
  lat: 53.5232,
  lng: -113.5263,
};

function Map() {
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const [searchBox, setSearchBox] = useState(null);

  const onLoad = (ref) => {
    setSearchBox(ref);
  };

  const onPlacesChanged = () => {
    const places = searchBox.getPlaces();
    const bounds = new window.google.maps.LatLngBounds();

    places.forEach((place) => {
      if (place.geometry && place.geometry.viewport) {
        bounds.union(place.geometry.viewport);
      } else if (place.geometry && place.geometry.location) {
        bounds.extend(place.geometry.location);
      }
    });

    const nextCenter = bounds.getCenter();
    const nextZoom = 10;

    setSelectedUniversity(null);
    setSearchBox(null);
    window.setTimeout(() => {
      setSearchBox(ref);
    }, 2000);

    setCenter({
      lat: nextCenter.lat(),
      lng: nextCenter.lng(),
    });
    setZoom(nextZoom);
  };

  const onSelect = (university) => {
    setSelectedUniversity(university);
  };

  const mapStyles = [
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ];

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={3}
      center={center}
      options={{ styles: mapStyles }}
    >
      <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
        <input type="text" placeholder="Enter a location" />
      </StandaloneSearchBox>

      <MarkerClusterer
        options={{
          imagePath: logo,
        }}
      >
        {(clusterer) =>
          universitiesData.map((university) => (
            <Marker
              key={university.id}
              position={{
                lat: university.coordinates[1],
                lng: university.coordinates[0],
              }}
              onClick={() => onSelect(university)}
              clusterer={clusterer}
              icon={{
                url: logo,
                scaledSize: new window.google.maps.Size(25, 25),
              }}
            />
          ))
        }
      </MarkerClusterer>

      {selectedUniversity && (
        <InfoWindow
          position={{
            lat: selectedUniversity.coordinates[1],
            lng: selectedUniversity.coordinates[0],
          }}
          onCloseClick={() => {
            setSelectedUniversity(null);
          }}
        >
          <div>
            <h2>{selectedUniversity.name}</h2>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
}

export default function FellowshipMap() {
  const [center, setCenter] = useState({ lat: 53.5232, lng: -113.5263 });
  const [zoom, setZoom] = useState(3);
  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        libraries={["places"]}
      >
        <Map
          center={center}
          zoom={zoom}
          setCenter={setCenter}
          setZoom={setZoom}
        />
      </LoadScript>
    </div>
  );
}
