import React from "react";
import { Box, HStack, Image } from "@chakra-ui/react";
import {
  Provider,
  Carousel as ChakraCarousel,
  LeftButton,
  RightButton,
} from "chakra-ui-carousel";
import { images } from "../assets/fellowships";

function Carousel() {
  console.log(images);
  return (
    <Box>
      <Provider>
        <ChakraCarousel gap={0}>
          {images.map((image, index) => (
            <Image
              key={index}
              src={image}
              alt={`carousel item ${index + 1}`}
              boxSize="500px"
              objectFit="cover"
            />
          ))}
        </ChakraCarousel>
        <HStack spacing={4} align="center" justify="center">
          <LeftButton
            bgColor="project.theme"
            _hover={{
              bg: "project.theme50",
            }}
            p={1}
          />
          <RightButton
            bgColor="project.theme"
            _hover={{
              bg: "project.theme50",
            }}
            p={1}
          />
        </HStack>
      </Provider>
    </Box>
  );
}

export default Carousel;
